import React, { Component } from 'react';
import { Button, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';

class Page404 extends Component {

  handleDashboard() {
    window.location.href = '#/dashboard'

  }
  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="12" style={{ position: 'absolute', bottom: 0 }}>
              <marquee style={{ fontSize: 34, fontWeight: 700, color: 'blue' }} className="bold-y mr-4">Cảm ơn quý khách đã sử dụng dịch vụ của Tiệm Giặt Ký!</marquee>
              {/* <h4 className="pt-3">Oops! You're lost.</h4> */}
              {/* <p className="text-muted float-left">The page you are looking for was not found.</p> */}
              {/* <InputGroup className="input-prepend">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-search"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input size="16" type="text" placeholder="What are you looking for?" />
                <InputGroupAddon addonType="append">
                  <Button color="info">Search</Button>
                </InputGroupAddon>
              </InputGroup> */}
            </Col>
          </Row>
          {/* <Col sm="12" md={{ size: 6, offset: 3 }}>
            <Button onClick={this.handleDashboard.bind(this)} size="lg" color="info" >Home</Button>
          </Col> */}
        </Container>
      </div >
    );
  }
}

export default Page404;
