var initialState = {
    partners: [],
    partnersReport: []
}

const PartnerReducer = (state = initialState, action) => {
    let partners = [...state.partners];
    let index = -1;
    let id;
    switch (action.type) {
        case 'FETCH_PARTNERS':
            console.log({action})
            return Object.assign({}, state, { partners: action.partners })
        case 'FETCH_PARTNERS_REPORT':
            console.log({action})
            return Object.assign({}, state, { partnersReport: action.partners })
        case 'ADD_PARTNER':
            partners.push(action.partner)
            return { ...state, partners }
        case 'UPDATE_PARTNER':
            id = action.partner.id;
            index = findIndex(state, id)
            if (index === -1) partners.push(action.partner)
            else partners[index] = action.partner
            return { ...state, partners }
        case 'DELETE_PARTNER':
            index = findIndex(state, action.id)
            partners.splice(index, 1);
            return { ...state, partners };
        default:
            return state;
    }
}


let findIndex = (partners, id) => {
    let result = -1;
    partners.partners.forEach((partner, index) => {
        if (partner.id === id) {
            result = index;
        }
    });

    return result;
}

export default PartnerReducer;