import React, { Component } from 'react';
import {
  Badge, Card, CardBody, CardHeader, Col, Row, Table, Button,
  Modal,
  Alert,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ModalHeader
} from 'reactstrap';
import moment from 'moment'
import '../../../index.css'
import { AppSwitch } from '@coreui/react'
import { api } from '../../../views'
// import usersData from './UsersData'
import { connect } from "react-redux";

function UserRow(props) {
  const user = props.user
  const actGetUser = props.actGetUser
  const userLink = `#/users/${user.id}`

  async function handleUpdateNo() {
    if (confirm('Bạn chắc chắn muốn hủy đơn đăng kí này không ?')) { //eslint-disable-line
      api.dataService.actDeleteUserRequest(user.id)
      // api.dataService.actFetchUsersRequest(0, 10, '', '', '')
      // window.location.reload(true);
      actGetUser()
    }

  }
  async function handleUpdateYes() {
    const user = props.user
    var id = user.id
    var users = {
      id: id,
      isVerify: true
    }
    if (confirm('Bạn chắc chắn muốn duyệt người dùng ?')) { //eslint-disable-line
      await api.dataService.actUpdateUSerRequest(users);
      // api.dataService.actFetchUsersRequest(0, 10, '', '', '')
      actGetUser()
    }

    // window.location.reload(true);

  }
  return (
    <tr >
      <th scope="row" className="text-center"><a href={userLink}>{user.id}</a></th>
      <td className="text-center"><a href={userLink}>{user.account}</a></td>
      <td className="text-center"><div>{user.fullName}</div>
        <div className="small text-muted">
          <span className="bold-x">New</span> | {moment(user.createdAt).format('LLL')}
        </div></td>
      <td className="text-center">{user.phone}</td>
      <td className="text-center">{user.email}</td>
      <td className="text-center"><img className="img-avatar-user" src={user.avatar} alt={user.fullName} style={{ height: 80, width: 80 }} /></td>
      {/* <td><Badge href={userLink} color={getBadge(user.locked)}>{user.locked}</Badge></td> */}
      {/* <td>  <AppSwitch className={'mx-1'} id="locked" name="locked" checked={user.locked ? true : false} color={'primary'} label /></td> */}
      <td className="text-center"> <Row><Col xs="6"><Button outline color="success" size="sm" block onClick={handleUpdateYes}>Đồng ý</Button> </Col> <Col xs="6"><Button outline color="danger" size="sm" block onClick={handleUpdateNo}>Hủy bỏ</Button></Col></Row>  </td>
    </tr >
  )
}

class UsersPending extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      account: '',
      phone: '',
      users: []
    };
    this.toggleSuccess = this.toggleSuccess.bind(this);
  }

  toggleSuccess() {
    this.setState({
      success: !this.state.success,
    });
  }
  componentDidMount() {
    this.actGetUser()
  }
  actGetUser = async () => {
    var result = await api.dataService.actFetchUsersRequest(0, 100, '', { roleIds: { "contains": 2 }, isVerify: false }, [{ id: 'ASC' }])
    this.setState({ users: result.data })
  }

  onChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value
    });
  }
  onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      this.onSubmitSearch();
    }
  }
  onSubmitSearch = async e => {
    e.preventDefault();
    e.target.reset()
    var { account, phone } = this.state;
    let where = {};

    if (account) {
      where.account = { 'contains': account }
    }
    {/* if (phone) {
      where.phone = { 'contains': phone }
    } */}
    var result = await api.dataService.actFetchUsersRequest(0, 100, '', where, [{ id: 'ASC' }])
    this.setState({ users: result.data })

  }
  render() {
    var { users, account, phone } = this.state;

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i> Bộ lọc
              </CardHeader>
              <CardBody>
                <Form onSubmit={this.onSubmitSearch}>
                  <Row form>
                    {/* <Col md={4}>
                      <FormGroup>
                        <Label for="exampleCity">Mã</Label>
                        <Input type="text" id="id" name="id" value={id} onChange={this.onChange.bind(this)} />
                      </FormGroup>
                    </Col> */}
                    <Col md={4}>
                      <FormGroup>
                        <Label for="exampleState">Tên tài khoản</Label>
                        <Input type="text" id="account" name="account" value={account} onChange={this.onChange.bind(this)} />
                      </FormGroup>
                    </Col>
                    {/* <Col md={4}>
                      <FormGroup row>
                        <Label for="exampleZip">Số điện thoại </Label>
                        <Input type="number" id="phone" name="phone" value={phone} min='0' step='1' onChange={this.onChange.bind(this)} />
                      </FormGroup>
                    </Col> */}
                  </Row>
                  <FormGroup className="form-actions">
                    <Button type="submit" size="sm" color="primary">Tìm kiếm</Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col xl={12}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i> {users.length > 0 ? `Dữ liệu (${users.length} mục)` : 'Đang xử lý ...'}
              </CardHeader>
              <CardBody>
                {users.length > 0 ?
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">ID</th>
                        <th scope="col" className="text-center">Tài khoản</th>
                        <th scope="col" className="text-center">Tên đầy đủ</th>
                        <th scope="col" className="text-center">Số điện thoại</th>
                        <th scope="col" className="text-center">Email</th>
                        <th scope="col" className="text-center">Avatar</th>

                        {/* <th scope="col">Khóa tài khoản</th> */}
                        <th scope="col" className="text-center">Hành động</th>
                      </tr>
                    </thead>
                    {users.map((user, index) =>
                      <tbody key={index}>
                        <UserRow user={user} actGetUser={this.actGetUser} />
                      </tbody>)}

                  </Table> : <Alert color="warning" className="center">
                    Không có người dùng nào đăng ký !
      </Alert>}
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <Modal isOpen={this.state.success} toggle={this.toggleSuccess}
          className={'modal-success ' + this.props.className}>
          <ModalHeader toggle={this.toggleSuccess}>Add User</ModalHeader>
          <ModalBody>
            <Form onSubmit={this.onSubmit}>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Tài khoản</InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" id="username" name="username" value={username} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Mật khẩu</InputGroupText>
                  </InputGroupAddon>
                  <Input type="password" id="password" name="password" value={password} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-asterisk"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Email</InputGroupText>
                  </InputGroupAddon>
                  <Input type="email" id="email" name="email" value={email} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-envelope"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Họ và tên</InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" id="fullname" name="fullname" value={fullname} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Địa chỉ</InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" id="address" name="address" value={address} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Mô tả chi tiết</InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" id="description" name="description" value={description} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>identityCard</InputGroupText>
                  </InputGroupAddon>
                  <Input type="number" id="identityCard" name="identityCard" value={identityCard} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Số điện thoại</InputGroupText>
                  </InputGroupAddon>
                  <Input type="number" id="phone" name="phone" value={phone} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup row>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>RoleIds</InputGroupText>
                  </InputGroupAddon>
                  <Input type="select" name="roleId" value={roleId} id="roleId" onChange={this.onChange.bind(this)} >
                    <option value="">-- select --</option>
                    {roleIds.length > 0 ? roleIds.map(a => {
                      return (< option key={a.id} value={a.id} > {a.name}</option>)
                    }) : ''}

                  </Input>
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup className="form-actions">
                <Button type="submit" size="sm" color="primary">Submit</Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal> */}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
    listUsers: state.listUsers,
  }
}
export default connect(mapStateToProps)(UsersPending);

