import React, { Component } from 'react';
import {
  Button, Card, CardBody, CardHeader, Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Col, Row,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ModalHeader, Alert, Table
} from 'reactstrap';
import { api } from '../../../views'
import 'react-select/dist/react-select.css'
import Select from "react-virtualized-select";
import qs from 'query-string';
class AreaCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      areaCategory: '',
      productCategory: '',
      productCategoryId: '',
      nameProductCategory: '',
      appProductCategory: '',
      id: '',
      name: '',
      nameError: '',
    };
    this.toggleSuccess = this.toggleSuccess.bind(this);
  }
  componentDidMount() {
    // if (this.props.match.params.id) {
    this.getAppProductCategory()
    this.getAreaCategory()
    if (this.props.match.params.id) {
      this.getCategoryAppId()
    }
    // } else {
    //   api.api.showMessage('Lỗi đường Link, vui lòng thử lại', 'Thông báo');
    //   window.location.href = "/#/danhmucsanphamtheoapp/danhmucsanpham"
    // }
  }
  async getAppProductCategory() {
    var result = await api.dataService.getCategoryApp("0")
    this.setState({
      appProductCategory: result.data.data
    })
  }
  async getAreaCategory() {
    var id = this.props.match.params.id
    var result = await api.dataService.getCategoryApp(id, "1")
    this.setState({
      areaCategory: result.data.data
    })
    setTimeout(() => {
      console.log(id, "id")
      console.log(this.props.match.params.id, "props")
    }, 500);
  }
  async getCategoryAppId() {
    var result = await api.dataService.getCategoryAppId(this.props.match.params.id)
    this.setState({
      id: result.data.data[0].id,
      nameProductCategory: result.data.data[0].name,
    })
  }
  toggleSuccess() {
    this.setState({
      success: !this.state.success,
    });

  }
  updateProductCategory = async (e) => {
    e.preventDefault();
    e.target.reset();
    var { nameProductCategory } = this.state;
    var category = {
      name: nameProductCategory
    }
    if (!nameProductCategory) {
      api.api.showMessage('Vui lòng không bỏ trống tên danh mục sản phẩm', 'Thông báo');
    } else {
      await api.dataService.updateCategoryApp(this.props.match.params.id, category)

      this.setState({
        // success: !this.state.success,
        nameProductCategory: '',
      })
      setTimeout(() => {
        window.location.reload()
      }, 500);
    }

  }
  validate = () => {
    let nameError = "";
    var { name } = this.state;
    if (!name) {
      nameError = 'Tên khu vực không được để trống !'
    }
    if (nameError) {
      this.setState({ nameError });
      return false;
    }
    return true
  }
  onChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value
    });
  }
  onSubmit = async e => {
    e.preventDefault();
    e.target.reset()
    const isValid = this.validate()
    var { name } = this.state;
    if (isValid) {
      var category = {
        name: name
      }
      await api.dataService.addNewCategoryApp(this.props.match.params.id, "1", category)

      this.setState({
        success: !this.state.success,
        name: '',
      })
      setTimeout(() => {
        this.getAreaCategory()
      }, 500);
    }
  }
  handeleDeleteCategory(data) {
    if (confirm('Bạn chắc chắn muốn xóa ?')) { //eslint-disable-line
      api.dataService.deleteCategoryApp(data)
      setTimeout(() => {
        this.getCategoryApp(0)
      }, 500);
    }
  }
  render() {
    var { areaCategory, productCategory, productCategoryId, id, name, nameError, nameProductCategory, appProductCategory } = this.state
    const options = productCategory.length > 0 ? productCategory.map((a) => {
      return (
        { label: a.name, value: a.id, key: a.id }

      )
    }) : ''

    return (
      <div className="animated fadeIn">
        <Row>
          {this.props.match.params.id ?
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <i className="fa fa-align-justify"></i> Cập nhập danh mục
              </CardHeader>
                <CardBody>
                  <Form onSubmit={this.updateProductCategory}>
                    <Row>

                      {/* <Col md={4}>
                    <Label for="exampleState">Danh mục sản phẩm</Label>
                    <Select options={options}
                      onChange={(productCategoryId) => {
                        productCategoryId = productCategoryId || { value: '', label: '' }
                        this.setState({ productCategoryId })
                      }}
                      value={productCategoryId}
                      placeholder="Search"
                    />
                  </Col> */}
                      <Col md={4}>
                        {/* <FormGroup>
                        <Label>Mã danh mục sản phẩm</Label>
                        <Input type="text" id="id" name="id" value={id} readOnly></Input><br></br>
                      </FormGroup> */}
                        <FormGroup>
                          <Label>Tên danh mục sản phẩm</Label>
                          <Input type="text" id="name" name="nameProductCategory" value={nameProductCategory} onChange={this.onChange}></Input>
                        </FormGroup>
                        <Button type="submit" color="success" className="float-right" style={{ margin: ' 10px 0' }}>Lưu</Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            : null}
          {this.props.match.params.id ?
            <Col xs={12} >
              <Button active color="success" onClick={this.toggleSuccess} className="float-right">Thêm tên miền vào: {nameProductCategory}</Button>
            </Col>
            : null}
          <br />
          <br />
          <br />
          <Modal isOpen={this.state.success} toggle={this.toggleSuccess}
            className={'modal-success ' + this.props.className}>
            <ModalHeader toggle={this.toggleSuccess}>Thêm tên miền vào: {nameProductCategory}</ModalHeader>
            <ModalBody>
              <Form onSubmit={this.onSubmit}>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Tên danh mục sản phẩm</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" id="name" name="name" value={name} onChange={this.onChange.bind(this)} />
                    <InputGroupAddon addonType="append">
                      <InputGroupText></InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {nameError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{nameError}</i> </p> : ''}
                </FormGroup>

                <FormGroup>
                  {/* <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Chi tiết danh mục sản phẩm</InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" id="description" name="description" value={description} onChange={this.onChange.bind(this)} />
                        <InputGroupAddon addonType="append">
                          <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      {descriptionError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{descriptionError}</i> </p> : ''}
                    </FormGroup>
                    <FormGroup className="form-actions">
                       */}
                </FormGroup>
                <Button type="submit" size="sm" color="primary">Thêm danh mục sản phẩm</Button>
              </Form>
            </ModalBody>
          </Modal>

          <Col xs={12}>
            <Card>
              <CardHeader>
                {/* <i className="fa fa-align-justify"></i> {listCategories.length > 0 ? `Danh mục sản phẩm (${listCategories.length} mục)` : 'Đang xử lý ...'} */}
              </CardHeader>
              <CardBody>
                {
                  areaCategory.length > 0 ?
                    <Table responsive bordered>
                      <thead>
                        <tr>
                          <th className="text-center">Id</th>
                          <th className="text-center">Tên miền</th>
                          <th className="text-center">Thuộc danh mục sản phẩm</th>
                        </tr>
                      </thead>
                      <tbody>
                        {areaCategory.map((areaCategory, index) => {
                          return (
                            <tr key={index}>
                              <td className="text-center">{areaCategory.id}</td>
                              <td className="text-center">
                                <a href={"/#/danhmucsanphamtheoapp/danhmucdichvu/" + areaCategory.id}>{areaCategory.name} </a>
                              </td>
                              <td className="text-center">
                                {appProductCategory ? appProductCategory.find(x => x.id === areaCategory.parentid).name : null}
                              </td>
                              {/* <td className="text-center">
                                <Button size="sm" outline color="danger" size="sm" block onClick={() => this.handeleDeleteCategory(areaCategory.id)}>Xóa</Button>
                              </td> */}
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                    : <Alert color="warning" className="center">
                      Không có dữ liệu !
                    </Alert>
                }

              </CardBody>
            </Card>
          </Col>

        </Row>
      </div>
    )
  }
}
export default AreaCategory;