import React, { Component } from 'react';
import {
  Button, Row, Card, CardBody, CardHeader, Col, Pagination, PaginationItem, PaginationLink, Table, Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ModalHeader
} from 'reactstrap';
import { api } from '../../../views'
import { connect } from "react-redux";

function StoreRow(props) {
  const storearea = props.storearea
  console.log('bbb', storearea)
  const storeLink = `#/quanlycuahang/storesarea/${storearea.id}`

  return (
    <tr >
      <th scope="row" className="text-center"><a href={storeLink}>{storearea.id}</a></th>
      <td className="text-center"><a href={storeLink}>{storearea.name}</a></td>
      <td className="text-center">{storearea.description}</td>
    </tr >
  )
}

class StoreAreas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      name: '',
      nameError: '',
      description: '',
      descriptionError: '',
      id: '',
    };
    this.toggleSuccess = this.toggleSuccess.bind(this);
  }
  validate = () => {
    let nameError = "";
    let descriptionError = "";
    var { name, description } = this.state;
    if (!name) {
      nameError = 'Tên khu vực không được để trống !'
    }
    if (!description) {
      descriptionError = 'Mô tả không được để trống !'
    }
    if (nameError || descriptionError) {
      this.setState({ nameError, descriptionError });
      return false;
    }
    return true
  }
  toggleSuccess() {
    this.setState({
      success: !this.state.success,
    });
  }
  componentDidMount() {
    api.dataService.actFetchStoreAreaRequest('', '', '')
  }
  onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      this.onSubmit();
    }
  }
  onSubmit = async e => {
    e.preventDefault();
    e.target.reset()
    const isValid = this.validate()
    var { name, description } = this.state;
    if (isValid) {
      var storearea = {
        name: name,
        description: description

      }
      // api.dataService.actUpdateRoomRequest(rooms)
      // if (id) {
      //     api.dataService.actUpdateRoomRequest(rooms)
      // }
      // else {
      //     api.dataService.actAddRoomRequest(rooms)
      // }
      await api.dataService.actAddStoreAreaRequest(storearea)

      this.setState({
        success: !this.state.success,
        name: '',
        description: ''
      })
    }

    // api.dataService.actFetchRoomsRequest();
  }
  onChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value
    });
  }

  render() {
    var { name, description, descriptionError, nameError } = this.state;
    var { listStoreAreas } = this.props;
    listStoreAreas = this.props.listStoreAreas.storeareas
    console.log('xxx', listStoreAreas)
    return (
      <Row className="animated fadeIn">

        <Col xs={12} >
          <Button active color="success" onClick={this.toggleSuccess} className="float-right">Thêm mới khu vực</Button>
        </Col>
        <br />
        <br />
        <br />
        <Col xs={12}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> {listStoreAreas.length > 0 ? `Danh sách khu vực (${listStoreAreas.length} mục)` : 'Đang xử lý ...'}
            </CardHeader>
            <CardBody>
              <Table hover bordered striped responsive size="sm">
                <thead>
                  <tr>
                    <th className="text-center">ID</th>
                    <th className="text-center">Tên khu vực</th>
                    <th className="text-center">Mô tả chi tiết</th>
                  </tr>
                </thead>
                <tbody>
                  {listStoreAreas.length > 0 ? listStoreAreas.map((storearea, index) =>
                    <StoreRow key={index} storearea={storearea} />
                  ) : <span>Không có dữ liệu !</span>}

                </tbody>
              </Table>
              {/* <div style={{ display: 'table', margin: '0 auto' }}>
                <Pagination>
                  <PaginationItem><PaginationLink previous tag="button">Prev</PaginationLink></PaginationItem>
                  <PaginationItem active>
                    <PaginationLink tag="button">1</PaginationLink>
                  </PaginationItem>
                  <PaginationItem><PaginationLink tag="button">2</PaginationLink></PaginationItem>
                  <PaginationItem><PaginationLink tag="button">3</PaginationLink></PaginationItem>
                  <PaginationItem><PaginationLink tag="button">4</PaginationLink></PaginationItem>
                  <PaginationItem><PaginationLink next tag="button">Next</PaginationLink></PaginationItem>
                </Pagination>
              </div> */}
            </CardBody>
          </Card>
        </Col>
        <Modal isOpen={this.state.success} toggle={this.toggleSuccess}
          className={'modal-success ' + this.props.className}>
          <ModalHeader toggle={this.toggleSuccess}>Thêm mới cửa hàng</ModalHeader>
          <ModalBody>
            <Form onSubmit={this.onSubmit}>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Tên khu vực</InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" id="name" name="name" value={name} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {nameError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{nameError}</i> </p> : ''}
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Thêm mô tả chi tiết</InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" id="description" name="description" value={description} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {descriptionError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{descriptionError}</i> </p> : ''}
              </FormGroup>
              {/* <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>identityCard</InputGroupText>
                  </InputGroupAddon>
                  <Input type="number" id="identityCard" name="identityCard" value={identityCard} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup> */}

              <FormGroup className="form-actions">
                <Button type="submit" size="sm" color="primary">Lưu</Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </Row>
    );
  }
}
const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
    listStoreAreas: state.listStoreAreas,
  }
}
export default connect(mapStateToProps)(StoreAreas);


