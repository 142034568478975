
export default {
  items: [
    // {
    //   name: 'Dashboard',
    //   url: '/dashboard',
    //   icon: 'icon-speedometer',
    //   badge: {
    //     variant: 'info',
    //     text: 'NEW',
    //   },
    // },
    // {
    //   title: true,
    //   name: 'Theme',
    //   wrapper: {            // optional wrapper object
    //     element: '',        // required valid HTML5 element tag
    //     attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
    //   },
    //   class: ''             // optional class names space delimited list for title item ex: "text-center"
    // },
    // {
    //   name: 'Colors',
    //   url: '/theme/colors',
    //   icon: 'icon-drop',
    // },
    // {
    //   name: 'Typography',
    //   url: '/theme/typography',
    //   icon: 'icon-pencil',
    // },
 
    {
      name: 'Quản lý Voucher',
      url: '/voucher',
      icon: 'cui-layers',
      children: [
        {
          name: 'Danh sách khách hàng sử dụng voucher đối tác',
          url: '/voucher/list-customer',
          icon: 'cui-note',
        }
      ],
    },


   
    // {
    //   name: 'Widgets',
    //   url: '/widgets',
    //   icon: 'icon-calculator',
    //   badge: {
    //     variant: 'info',
    //     text: 'NEW',
    //   },
    // },
    {
      divider: true,
    },
    // {
    //   title: true,
    //   name: 'ĐĂNG NHẬP VÀ BẢO MẬT',
    // },
    // {
    //   name: 'ĐĂNG NHẬP',
    //   url: '/pages',
    //   icon: 'cui-user',
    //   children: [
    //     {
    //       name: 'Login',
    //       url: '/login',
    //       icon: 'cui-user',
    //     },

    //   ],
    // },
    // {
    //   name: 'Wash Admin',
    //   url: '',
    //   icon: 'icon-cloud-download',
    //   class: 'mt-auto',
    //   variant: 'success',
    // },
    // {
    //   name: 'Download CoreUI',
    //   url: 'http://coreui.io/react/',
    //   icon: 'icon-cloud-download',
    //   class: 'mt-auto',
    //   variant: 'success',
    // },
    // {
    //   name: 'Try CoreUI PRO',
    //   url: 'http://coreui.io/pro/react/',
    //   icon: 'icon-layers',
    //   variant: 'danger',
    // },
  ],
};
