import React, { Component } from 'react';
import {
  Button, Card, CardBody, CardHeader, Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Col, Row,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ModalHeader, Alert, Table,
} from 'reactstrap';
import { api } from '../../../views'

class AppProductCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      nameError: '',
      success: false,
      appProductCategory: '',
    };
    this.toggleSuccess = this.toggleSuccess.bind(this);
  }
  validate = () => {
    let nameError = "";
    var { name } = this.state;
    if (!name) {
      nameError = 'Tên khu vực không được để trống !'
    }
    if (nameError) {
      this.setState({ nameError });
      return false;
    }
    return true
  }

  onSubmit = async e => {
    e.preventDefault();
    e.target.reset()
    const isValid = this.validate()
    var { name } = this.state;
    if (isValid) {
      var category = {
        name: name
      }
      // api.dataService.actUpdateRoomRequest(rooms)
      // if (id) {
      //     api.dataService.actUpdateRoomRequest(rooms)
      // }
      // else {
      //     api.dataService.actAddRoomRequest(rooms)
      // }
      await api.dataService.addNewCategoryApp("0", "0", category)

      this.setState({
        success: !this.state.success,
        name: '',
      })
      setTimeout(() => {
        this.getCategoryApp(0)
      }, 500);
    }

    // api.dataService.actFetchRoomsRequest();
  }
  onChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value
    });
  }

  async getCategoryApp() {
    var result = await api.dataService.getCategoryApp("0")
    this.setState({
      appProductCategory: result.data.data,
    })
    setTimeout(() => {
      console.log(this.state.appProductCategory, "appProductCategory")
    }, 500);
  }
  componentDidMount() {
    this.getCategoryApp(0)
  }
  toggleSuccess() {
    this.setState({
      success: !this.state.success,
    });
  }
  handeleDeleteCategory(data) {
    if (confirm('Bạn chắc chắn muốn xóa ?')) { //eslint-disable-line
      api.dataService.deleteCategoryApp(data)
      setTimeout(() => {
        this.getCategoryApp(0)
      }, 500);
    }
  }
  render() {
    // var { name, description, nameError, descriptionError } = this.state;
    // var { listCategories } = this.props;
    // listCategories = this.props.listCategories.categories
    var { appProductCategory, nameError, name } = this.state;
    console.log(appProductCategory, "abc")
    return (
      <div className="animated fadeIn">
        <Row>

          <Col xs={12} >
            <Button active color="success" onClick={this.toggleSuccess} className="float-right">Thêm danh mục sản phẩm</Button>
          </Col>
          <br />
          <br />
          <br />
          <Col xs={12}>
            <Card>
              <CardHeader>
                {/* <i className="fa fa-align-justify"></i> {listCategories.length > 0 ? `Danh mục sản phẩm (${listCategories.length} mục)` : 'Đang xử lý ...'} */}
              </CardHeader>
              <CardBody>
                {
                  appProductCategory.length > 0 ?
                    <Table responsive bordered>
                      <thead>
                        <tr>
                          <th className="text-center">#</th>
                          <th className="text-center">Tên danh  mục</th>
                          {/* <th className="text-center">Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {appProductCategory.map((productCategory, index) => {
                          return (
                            <tr key={index}>
                              <td className="text-center">{productCategory.id}</td>
                              {/* <td>{productCategory.name}</td> */}
                              <td className="text-center">
                                <a href={"/#/danhmucsanphamtheoapp/danhmucmien/" + productCategory.id}>{productCategory.name} </a>
                              </td>
                              {/* <td className="text-center">
                                <Button size="sm" outline color="danger" size="sm" block onClick={() => this.handeleDeleteCategory(productCategory.id)}>Xóa</Button>
                              </td> */}
                            </tr>
                          )
                        }
                        )}
                      </tbody>
                    </Table>
                    : <Alert color="warning" className="center">
                      Không có dữ liệu !
                  </Alert>
                }

              </CardBody>
            </Card>
          </Col>
          <Modal isOpen={this.state.success} toggle={this.toggleSuccess}
            className={'modal-success ' + this.props.className}>
            <ModalHeader toggle={this.toggleSuccess}>Thêm danh mục sản phẩm</ModalHeader>
            <ModalBody>
              <Form onSubmit={this.onSubmit}>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Tên danh mục sản phẩm</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" id="name" name="name" value={name} onChange={this.onChange.bind(this)} />
                    <InputGroupAddon addonType="append">
                      <InputGroupText></InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {nameError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{nameError}</i> </p> : ''}
                </FormGroup>

                <FormGroup>
                  {/* <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Chi tiết danh mục sản phẩm</InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" id="description" name="description" value={description} onChange={this.onChange.bind(this)} />
                        <InputGroupAddon addonType="append">
                          <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      {descriptionError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{descriptionError}</i> </p> : ''}
                    </FormGroup>
                    <FormGroup className="form-actions">
                       */}
                </FormGroup>
                <Button type="submit" size="sm" color="primary">Thêm danh mục sản phẩm</Button>
              </Form>
            </ModalBody>
          </Modal>
        </Row>
      </div>
    )
  }
}
export default AppProductCategory;