import React, { Component } from 'react';
import {
  Col, Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  CustomInput,

} from 'reactstrap';
import qs from 'query-string';
import { api } from '../../../views'
import '../../../index.css'
import StarRatingComponent from 'react-star-rating-component';

class CustomerReviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 0,
      billId: '',
      customerNote: '',
      barcode: '',
      ratingIds: [],
      ratingId: []
    };
  }

  onStarClick(nextValue, prevValue, name) {
    this.setState({ rating: nextValue });
  }
  componentDidMount() {
    this.actCheckBarcode()
    this.actGetRating()
  }
  componentWillMount() {

  }
  actCheckBarcode() {
    var barcode = qs.parse(this.props.location.search)
    if (barcode.barcode) {
      this.setState({ barcode: barcode.barcode })
    }
    else {
      // alert('Link không tồn tại !')
      // this.props.history.push('/login')
    }
  }
  onChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value
    });
  }
  handleRatingCheck = async (event) => {
    var { ratingId } = this.state
    const target = event.target;
    const value = target.id;

    if (ratingId) {
      var array = [...ratingId]
    } else {
      var array = [];
    }
    if (target.checked) {
      array.push(parseInt(value))
    } else {
      array = array.filter(v => v !== parseInt(value))
    }
    await this.setState({
      ratingId: array
    })

  }
  async actRating() {
    const { rating, customerNote, billId, ratingId } = this.state;
    var barcode = qs.parse(this.props.location.search)
    if (barcode) {
      var ratings = {
        barCode: barcode.barcode,
        billId: parseInt(billId),
        customerNote: customerNote,
        point: rating || 5,
        ratingIds: ratingId
      }
      var result = await api.dataService.actRating(ratings)
      if (result) {
        alert('Cảm ơn quý khách đã đánh giá chất lượng dịch vụ !')
        // api.api.showConfirm('Thông báo', 'Cảm ơn', 'OK', 'No', 'OK', 'No')
        this.props.history.push('/404')
      }
      else {
        alert('Mã Barcode đã tồn tại ! Quý khách vui lòng thử lại với mã khác, xin cảm ơn !')
      }
    }
    else {
      alert('Barcode không tồn tại !')
    }
  }
  async actGetRating() {
    var result = await api.dataService.actFetchRating('', '', '', '', '')
    if (result.status === 200) {
      this.setState({ ratingIds: result.data.data })
    }
  }
  render() {
    const { rating, customerNote, billId, ratingIds, ratingId } = this.state;
    var barcode = qs.parse(this.props.location.search)
    // console.log('uuu', ratingId)
    return (
      <div className="customerreviews">
        <Alert color="danger" className="center">
          Đánh giá của khách hàng !
      </Alert>
        <Form lg>
          <FormGroup row>
            <Label for="exampleEmail" sm={3} className="bold">Mã hóa đơn : </Label>
            <Col sm={9}>
              <Input type="number" name="billId" value={billId} id="billId" min={0} step={1} onChange={this.onChange.bind(this)} placeholder="Mời bạn nhập vào mã hóa đơn ..." />
            </Col>
          </FormGroup>
          {/* <FormGroup row>
            <Label for="exampleEmail" sm={3}>BillId</Label>
            <Col sm={9}>
              <Input type="number" name="billId" id="billId" placeholder="with a placeholder" />
            </Col>
          </FormGroup> */}
          <FormGroup row>
            <Label for="exampleEmail" sm={3} className="bold rating">Chất lượng dịch vụ : </Label>
            <Col sm={9} >
              <StarRatingComponent
                name="rate1"
                starCount={5}
                value={rating}
                onStarClick={this.onStarClick.bind(this)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="exampleText" sm={3} className="bold">Ý kiến khác :</Label>
            <Col sm={9}>
              <Input type="textarea" name="customerNote" value={customerNote} id="customerNote" onChange={this.onChange.bind(this)} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="exampleCheckbox" sm={3} className="bold">Nhận xét :</Label>
            <Col sm={9}>
              {!ratingIds ? '' : ratingIds.map((a, index) => {
                return (
                  <CustomInput type="checkbox" id={a.id} name={a.id} key={index} label={a.name} onChange={this.handleRatingCheck.bind(this)} />
                )
              })}
            </Col>


          </FormGroup>

          <FormGroup check row>
            <Col className="right">
              <Button className="right" color="success" onClick={this.actRating.bind(this)}>Gửi</Button>
            </Col>
          </FormGroup>
        </Form>

      </div>
    )
  }
}

export default CustomerReviews;

