var initialState = {
  storeareas: []
}

const listStoreAreas = (state = initialState, action) => {
  let storeareas = [...state.storeareas];
  var index = -1;
  var id = '';
  switch (action.type) {
    case 'FETCH_STOREAREAS':
      // console.log({ action })
      return Object.assign({}, state, { storeareas: action.storearea });
    case 'ADD_STOREAREA':
      // console.log({ action })
      storeareas.push(action.storearea);
      return { ...state, storeareas };
    case 'UPDATE_STOREAREA':
      // console.log({ actionUpdate: action })
      id = action.storearea.id;
      index = findIndex(state, { id });
      if (index == -1) {
        storeareas.push(action.storearea);
      } else {
        storeareas[index] = action.storearea
      }

      // console.log('index', index)
      // users[index] = action.room;
      // console.log('storeareas', storeareas)
      // return { ...state, users };
      return { ...state, storeareas };
    case 'DELETE_STOREAREA':
      // console.log({ action })
      index = findIndex(state, action.id);

      storeareas.splice(index, 1);
      return { ...state, storeareas };
    default: return state;
  }
}

var findIndex = (storeareas, id) => {
  let result = -1;
  storeareas.storeareas.forEach((storearea, index) => {
    if (storearea.id === id) {
      result = index;
    }
  });

  return result;
}

export default listStoreAreas;
