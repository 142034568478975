var initialState = {
  storeusers: []
}

const listStoreUsers = (state = initialState, action) => {
  let storeusers = [...state.storeusers];
  var index = -1;
  var id = '';
  switch (action.type) {
    case 'FETCH_STOREUSERS':
      // console.log({ action })
      return Object.assign({}, state, { storeusers: action.storeuser });
    case 'ADD_STOREUSER':
      // console.log({ action })
      storeusers.push(action.storeuser);
      return { ...state, storeusers };
    case 'DELETE_STOREUSER':
      // console.log({ action })
      index = findIndex(state, action.id);

      storeusers.splice(index, 1);
      return { ...state, storeusers };
    default: return state;
  }
}

var findIndex = (storeusers, id) => {
  let result = -1;
  storeusers.storeusers.forEach((storeuser, index) => {
    if (storeuser.id === id) {
      result = index;
    }
  });

  return result;
}

export default listStoreUsers;
