import React, { Component } from 'react';
import {
  Button, Card, CardBody, CardHeader, Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Col, Row,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ModalHeader, Alert, Table
} from 'reactstrap';
import { api } from '../..'
import { connect } from "react-redux";


function UserRow(props) {
  const category = props.category
  // console.log('ccc', category)
  const userLink = `#/quanlysanpham/danhmucsanpham/${category.id}`

  async function handleDeleteCategory() {
    if (confirm('Bạn chắc chắn muốn xóa ?')) { //eslint-disable-line
      api.dataService.actDeleteCategoryRequest(category.id)
    }

    // var userId = {
    //   userId: user.id
    // }

    // if (user.locked === true) {
    //   var result = await api.dataService.actUnBlockUser(userId)
    //   if (result.status === 200) {
    //     api.api.showMessage('UnBlock User thành công', 'Thông báo')
    //     api.dataService.actFetchUsersRequest('', 100, '')
    //   }
    //   else {
    //     api.api.showMessage('Hệ thông đang gặp lỗi ! Vui lòng thử lại trong vài phút !', 'Thông báo')
    //   }
    // }
    // else if (user.locked === false) {
    //   var result = await api.dataService.actBlockUser(userId)
    //   if (result.status === 200) {
    //     api.api.showMessage('Block User thành công', 'Thông báo')
    //     api.dataService.actFetchUsersRequest('', 100, '')

    //   }
    //   else {
    //     api.api.showMessage('Hệ thông đang gặp lỗi ! Vui lòng thử lại trong vài phút !', 'Thông báo')
    //   }
    // }

  }
  return (
    <tr >
      <th scope="row" className="text-center"><a href={userLink}>{category.id}</a></th>
      <td className="text-center"><a href={userLink}>{category.name}</a></td>
      <td className="text-center">{category.description}</td>
      <td className="text-center">  <Button outline color="success" size="sm" block onClick={handleDeleteCategory}>Xóa danh mục sản phẩm</Button></td>
    </tr >
  )
}

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      name: '',
      description: '',
      categoryId: '',
      categories: '',
      nameError: '',
      descriptionError: '',
    };
    this.toggleSuccess = this.toggleSuccess.bind(this);
  }
  validate = () => {
    let nameError = "";
    let descriptionError = "";
    var { name, description } = this.state;
    if (!name) {
      nameError = 'Trường này không được để trống !'
    }
    if (!description) {
      descriptionError = 'Trường này không được để trống !'
    }
    if (nameError || descriptionError) {
      this.setState({ nameError, descriptionError });
      return false;
    }
    return true
  }
  toggleSuccess() {
    this.setState({
      success: !this.state.success,
    });
  }
  componentDidMount() {
    api.dataService.actFetchCategoriesRequest(0, '', '', '', [{ id: 'ASC' }])
  }
  onChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value
    });
  }
  onSubmit = async e => {
    e.preventDefault();
    e.target.reset()
    var { name, description } = this.state;
    const isValid = this.validate()
    if (isValid) {
      var category = {
        name: name,
        description: description,
      }
      // api.dataService.actUpdateRoomRequest(rooms)
      // if (id) {
      //     api.dataService.actUpdateRoomRequest(rooms)
      // }
      // else {
      //     api.dataService.actAddRoomRequest(rooms)
      // }
      await api.dataService.actAddCategoryRequest(category)
      api.dataService.actFetchCategoriesRequest(0, '', '', '', '')
      this.setState({
        success: !this.state.success,
        name: '',
        description: '',
      })
    }
    // api.dataService.actFetchRoomsRequest();
  }
  onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      this.onSubmitSearch();
    }
  }
  onSubmitSearch = async e => {
    e.preventDefault();
    e.target.reset()
    var { name, description } = this.state;

    let where = {};

    if (name) {
      where.name = { 'contains': name }
    }
    if (description) {
      where.description = { 'contains': description }
    }
    api.dataService.actFetchCategoriesRequest(0, '', '', where, [{ name: 'ASC' }])




  }
  render() {
    var { name, description, nameError, descriptionError } = this.state;
    var { listCategories } = this.props;
    listCategories = this.props.listCategories.categories
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i> Bộ lọc
              </CardHeader>
              <CardBody>
                <Form onSubmit={this.onSubmitSearch}>
                  <Row form>
                    {/* <Col md={4}>
                      <FormGroup>
                        <Label for="exampleCity">Mã</Label>
                        <Input type="text" id="id" name="id" value={id} onChange={this.onChange.bind(this)} />
                      </FormGroup>
                    </Col> */}
                    <Col md={4}>
                      <FormGroup>
                        <Label for="exampleState">Tên</Label>
                        <Input type="text" id="name" name="name" value={name} onChange={this.onChange.bind(this)} />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup row>
                        <Label for="exampleZip">Chi tiết danh mục </Label>
                        <Input type="text" id="description" name="description" value={description} onChange={this.onChange.bind(this)} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup className="form-actions">
                    <Button type="submit" size="sm" color="primary">Tìm kiếm</Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>

          <Col xs={12} >
            <Button active color="success" onClick={this.toggleSuccess} className="float-right">Thêm danh mục sản phẩm</Button>
          </Col>
          <br />
          <br />
          <br />
          <Col xs={12}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i> {listCategories.length > 0 ? `Danh mục sản phẩm (${listCategories.length} mục)` : 'Đang xử lý ...'}
              </CardHeader>
              <CardBody>
                {listCategories.length > 0 ?
                  <Table responsive bordered>
                    <thead>
                      <tr>
                        <th className="text-center">#</th>
                        <th className="text-center">Tên danh  mục</th>
                        <th className="text-center">Chi tiết danh mục</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listCategories.map((category, index) =>
                        <UserRow key={index} category={category} />
                      )}
                    </tbody>
                  </Table> : <Alert color="warning" className="center">
                    Không có dữ liệu !
      </Alert>}

              </CardBody>
            </Card>
          </Col>
          <Modal isOpen={this.state.success} toggle={this.toggleSuccess}
            className={'modal-success ' + this.props.className}>
            <ModalHeader toggle={this.toggleSuccess}>Thêm danh mục sản phẩm</ModalHeader>
            <ModalBody>
              <Form onSubmit={this.onSubmit}>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Tên danh mục sản phẩm</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" id="name" name="name" value={name} onChange={this.onChange.bind(this)} />
                    <InputGroupAddon addonType="append">
                      <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {nameError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{nameError}</i> </p> : ''}
                </FormGroup>

                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Chi tiết danh mục sản phẩm</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" id="description" name="description" value={description} onChange={this.onChange.bind(this)} />
                    <InputGroupAddon addonType="append">
                      <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {descriptionError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{descriptionError}</i> </p> : ''}
                </FormGroup>
                <FormGroup className="form-actions">
                  <Button type="submit" size="sm" color="primary">Thêm danh mục sản phẩm</Button>
                </FormGroup>
              </Form>
            </ModalBody>
          </Modal>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
    listCategories: state.listCategories,
  }
}
export default connect(mapStateToProps)(Categories);