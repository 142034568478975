import Login from './Login';
import Page404 from './Page404';
import Page500 from './Page500';
import CustomerReviews from './CustomerReviews'
import Registrations from './Registrations'
import Register from './Register';
import PushNotice from './PushNotice';
import PushNoticeForAppUser from './PushNoticeForAppUser/PushNoticeForAppUser'
import BillCheck from './BillCheck';
export {
  Login, Page404, Page500, PushNotice,CustomerReviews, Registrations,Register,PushNoticeForAppUser,BillCheck
};
