import React, { Component } from 'react';
import {
  Button, Card, CardBody, CardHeader, Alert, Modal,
  ModalBody,
  Form,
  FormGroup,
  Row, Col,
  Input,
  InputGroup, Label,
  InputGroupAddon,
  InputGroupText,
  ModalHeader, Pagination, PaginationItem, PaginationLink, Table
} from 'reactstrap';
import { api } from '../../../views'
import { connect } from "react-redux";
import qs from 'query-string'
import '../../../index.css'
import 'react-select/dist/react-select.css'
import 'react-virtualized-select/styles.css'
import Select from "react-virtualized-select";

function UserRow(props) {
  const priceproduct = props.priceproduct
  console.log('uuu', priceproduct)
  const userLink = `#/quanlysanpham/giasanpham/${priceproduct.id}`

  async function handleDeletePriceProduct() {
    if (confirm('Bạn chắc chắn muốn xóa ?')) { //eslint-disable-line
      api.dataService.actDeletePriceProductRequest(priceproduct.id)
    }
  }
  function formatCurrency(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }
  return (
    <tr >
      <th scope="row" className="text-center"><a href={userLink}>{priceproduct.id}</a></th>
      <td className="text-center">
        <div className="small ">
          {formatCurrency(priceproduct.price)} VNĐ
                          </div>
        <a href={userLink}>
          Xem chi tiết </a></td>
      <td className="text-center">{priceproduct && priceproduct.productId ? priceproduct.productId.name : ''}</td>
      <td className="text-center">{priceproduct.storeAreaId.name}</td>
      <td className="text-center">  <Button outline color="success" size="sm" block onClick={handleDeletePriceProduct}>Xóa giá sản phẩm</Button></td>
    </tr >
  )
}

class PriceProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      price: '',
      productId: '',
      priceError: '',
      productIdError: '',
      storeAreaIdError: '',
      product: [],
      storeAreaId: '',
      storearea: [],
      count: 0,
      prev: 0,
      next: 2,
      last: 0,
      first: 1,
      currentPage: 1,
      pagesNumber: []
    };
    this.toggleSuccess = this.toggleSuccess.bind(this);
  }
  async handleClick(event) {
    let search = '?';
    let productId = qs.parse(this.props.location.search).productId;
    let storeAreaId = qs.parse(this.props.location.search).storeAreaId;
    let where = {};

    {/* if (price) {
  where.price = price
} */}
    if (productId) {
      where.productId = productId
      search += '&productId=' + productId
    }
    if (storeAreaId) {
      where.storeAreaId = storeAreaId
      search += '&storeAreaId=' + storeAreaId
    }
    if (event) {
      search += '&page=' + event;
    }
    await this.props.history.push(search)
    this.actFetchPriceProducts(where)
  }
  validate = () => {
    let priceError = "";
    let productIdError = "";
    let storeAreaIdError = "";
    var { price, productId, storeAreaId } = this.state;
    if (!price) {
      priceError = 'Trường này không được để trống !'
    }
    if (!productId) {
      productIdError = 'Vui lòng chọn tên sản phẩm !'
    }
    if (!storeAreaId) {
      storeAreaIdError = 'Vui lòng chọn khu vực sản phẩm !'
    }
    if (priceError || productIdError || storeAreaIdError) {
      this.setState({ priceError, productIdError, storeAreaIdError });
      return false;
    }
    return true
  }
  toggleSuccess() {
    this.setState({
      success: !this.state.success,
    });
  }
  componentDidMount() {
    this.actFetchPriceProducts()
    this.actGetProduct()
    this.actGetStorearea()
  }

  async actFetchPriceProducts(where) {
    let urlQueryObj = qs.parse(this.props.location.search);
    let currentPage = Number(urlQueryObj.page || 1);
    if (isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let limit = urlQueryObj.limit || 10;
    let skip = (currentPage - 1) * limit;
    var result = await api.dataService.actFetchPriceProductsRequest(skip, limit, '', where, [{ id: 'ASC' }])
    let count = result.count;


    let maxPage = Math.ceil(count / limit);
    //console.log({ maxPage });
    //console.log({ currentPage });

    let pagesNumber = [];

    if (maxPage <= 5) {
      for (let i = 0; i < maxPage; i++) {
        pagesNumber.push(i + 1);
      }

    }
    else if (maxPage > 5 && maxPage - 2 <= currentPage) {
      pagesNumber = [0, maxPage - 4, maxPage - 3, maxPage - 2, maxPage - 1, maxPage];
    } else if (currentPage < 4) {
      pagesNumber = [1, 2, 3, 4, 5];
      if (maxPage > 5) {
        pagesNumber.push(0);
      }
    } else {
      pagesNumber = [0, currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2, 0];
    }
    let next = currentPage + 1;

    let prev = currentPage - 1;

    this.setState({ next, prev, count, currentPage, last: maxPage || 1, pagesNumber });

  }

  async actGetStorearea() {
    var result = await api.dataService.actFetchStoreAreaRequest('', '', '', '')
    this.setState({ storearea: result.data })
  }
  async actGetProduct() {
    var result = await api.dataService.actFetchProductsRequest(0, 10000, '', '', '')
    this.setState({ product: result.data })
  }
  onChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value
    });
  }
  onSubmit = async e => {
    e.preventDefault();
    e.target.reset()
    var { price, product, productId, storearea, storeAreaId } = this.state;
    const isValid = this.validate()
    if (isValid) {
      var priceproduct = {
        price: price,
        storeAreaId: storeAreaId,
        productId: productId.value
      }
      await api.dataService.actAddPriceProductRequest(priceproduct)
      this.actFetchPriceProducts()
      this.setState({
        success: !this.state.success,
        price: '',
        productId: '',
        storeAreaId: ''
      })
    }

  }
  onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      this.onSubmitSearch();
    }
  }
  onSubmitSearch = async e => {
    e.preventDefault();
    e.target.reset()
    var { price, productId, storeAreaId } = this.state;
    let where = {};

    {/* if (price) {
      where.price = price
    } */}

    if (productId && productId.value) {
      where.productId = productId.value
    }
    if (storeAreaId && storeAreaId.value) {
      where.storeAreaId = storeAreaId.value
    }
    this.setState({ currentPage: 1 })
    await this.props.history.push('?page=' + 1 + '&productId=' + (productId.value || '') + '&storeAreaId=' + (storeAreaId.value || ''))
    this.actFetchPriceProducts(where)

  }
  render() {
    var { price, product, productId, count, storearea, storeAreaId, currentPage, prev, next, pagesNumber, last, priceError, productIdError, storeAreaIdError } = this.state;
    var { listPriceProducts } = this.props;
    listPriceProducts = this.props.listPriceProducts.priceproducts

    const options = product.length > 0 ? product.map((a) => {
      return (
        { label: a.name, value: a.id, key: a.id }

      )
    }) : ''
    const options2 = storearea.length > 0 ? storearea.map((a) => {
      return (
        { label: a.name, value: a.id, key: a.id }

      )
    }) : ''
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i> Bộ lọc
              </CardHeader>
              <CardBody>
                <Form onSubmit={this.onSubmitSearch}>
                  <Row form>
                    {/* <Col md={4}>
                      <FormGroup >
                        <Label for="exampleZip">Tên sản phẩm</Label>
                        <Input type="select" name="productId" value={productId} id="productId" onChange={this.onChange.bind(this)} >
                          <option value="">-- select --</option>
                          {product.length > 0 ? product.map(a => {
                            return (< option key={a.id} value={a.id} > {a.name}</option>)
                          }) : ''}

                        </Input>
                      </FormGroup>
                    </Col> */}
                    <Col md={4}>
                      <FormGroup>
                        <Label for="exampleCity">Sản phẩm</Label>
                        <Select options={options}
                          onChange={(productId) => {
                            productId = productId || { value: '', label: '' }
                            this.setState({ productId })
                          }}
                          value={productId}
                          placeholder="Search"
                        />
                      </FormGroup>
                    </Col>
                    {/* <Col md={4}>
                      <FormGroup >
                        <Label for="exampleZip">Khu vực</Label>
                        <Input type="select" name="storeAreaId" value={storeAreaId} id="storeAreaId" onChange={this.onChange.bind(this)} >
                          <option value="">-- select --</option>
                          {storearea.length > 0 ? storearea.map(a => {
                            return (< option key={a.id} value={a.id} > {a.name}</option>)
                          }) : ''}

                        </Input>
                      </FormGroup>
                    </Col> */}
                    <Col md={4}>
                      <FormGroup>
                        <Label for="exampleCity">Khu vực</Label>
                        <Select options={options2}
                          onChange={(storeAreaId) => {
                            storeAreaId = storeAreaId || { value: '', label: '' }
                            this.setState({ storeAreaId })
                          }}
                          value={storeAreaId}
                          placeholder="Search"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup className="form-actions">
                    <Button type="submit" size="sm" color="primary">Tìm kiếm</Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>

          <Col xs={12} >
            <Button active color="success" onClick={this.toggleSuccess} className="float-right">Thêm giá sản phẩm</Button>
          </Col>
          <br />
          <br />
          <br />
          <Col xs={12}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i>  {listPriceProducts.length > 0 ? `Danh sách giá sản phẩm (${count} mục)` : 'Đang xử lý ...'}
              </CardHeader>
              <CardBody>
                {listPriceProducts.length > 0 ?
                  <Table responsive bordered>
                    <thead>
                      <tr>
                        <th className="text-center">ID</th>
                        <th className="text-center">Gía sản phẩm</th>
                        <th className="text-center">Tên sản phẩm</th>
                        <th className="text-center">Khu vực sản phẩm</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listPriceProducts.map((priceproduct, index) =>
                        <UserRow key={index} priceproduct={priceproduct} />
                      )}
                    </tbody>
                  </Table> : <Alert color="warning" className="center">
                    Không có dữ liệu !
      </Alert>}
                <div style={{ display: 'table', margin: '0 auto' }}>
                  {pagesNumber.length == 1 ? '' : <Pagination >
                    <PaginationItem>
                      {prev === 0 ? <PaginationLink className="hide">First</PaginationLink> :
                        <PaginationLink onClick={() => this.handleClick(1)} id="first">First</PaginationLink>
                      }
                    </PaginationItem>
                    <PaginationItem>
                      {prev < 1 ? <PaginationLink className="hide"><i className="cui-chevron-left icons "></i></PaginationLink> :
                        <PaginationLink onClick={() => this.handleClick(prev)} id="prev"><i className="cui-chevron-left icons "></i></PaginationLink>
                      }
                    </PaginationItem>
                    {!pagesNumber ? '' : pagesNumber.map((pageNumber, index) => {
                      return (<Pagination key={index}>
                        <PaginationItem active={currentPage == (pageNumber)} >
                          {pageNumber ? <PaginationLink onClick={() => { this.handleClick(pageNumber) }} key={index} >
                            {pageNumber}
                          </PaginationLink> : <PaginationLink className="disabled" onClick={() => { this.handleClick(pageNumber) }} key={index} >
                              ...
                          </PaginationLink>
                          }
                        </PaginationItem>
                      </Pagination>)

                    })
                    }

                    <PaginationItem>
                      {
                        next > last ? <PaginationLink className="hide"><i className="cui-chevron-right icons "></i></PaginationLink> :
                          <PaginationLink onClick={() => this.handleClick(next)} id="next" > <i className="cui-chevron-right icons "></i></PaginationLink>
                      }
                    </PaginationItem>

                    <PaginationItem>
                      {
                        currentPage === last ? <PaginationLink className="hide">Last</PaginationLink> :
                          <PaginationLink onClick={() => this.handleClick(last)} id="last" >Last</PaginationLink>
                      }
                    </PaginationItem>
                  </Pagination>}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Modal isOpen={this.state.success} toggle={this.toggleSuccess}
            className={'modal-success ' + this.props.className}>
            <ModalHeader toggle={this.toggleSuccess}>Thêm giá sản phẩm</ModalHeader>
            <ModalBody>
              <Form onSubmit={this.onSubmit}>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Giá sản phẩm</InputGroupText>
                    </InputGroupAddon>
                    <Input type="number" id="price" name="price" value={price} min='0' step='1' onChange={this.onChange.bind(this)} />
                    <InputGroupAddon addonType="append">
                      <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {priceError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{priceError}</i> </p> : ''}
                </FormGroup>

                {/* <FormGroup >
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Tên sản phẩm</InputGroupText>
                    </InputGroupAddon>
                    <Input type="select" name="productId" value={productId} id="productId" onChange={this.onChange.bind(this)} >
                      <option value="">-- select --</option>
                      {product.length > 0 ? product.map(a => {
                        return (< option key={a.id} value={a.id} > {a.name}</option>)
                      }) : ''}

                    </Input>
                    <InputGroupAddon addonType="append">
                      <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {productIdError ? <p style={{ color: 'red', display: 'table', margin: '0 auto' }}> <i>{productIdError}</i> </p> : ''}
                </FormGroup> */}
                <FormGroup>

                  <Select options={options}
                    onChange={(productId) => {
                      productId = productId || { value: '', label: '' }
                      this.setState({ productId })
                    }}
                    value={productId}
                    placeholder="Tên sản phẩm ..."
                  />
                  {productIdError ? <p style={{ color: 'red', display: 'table', margin: '0 auto' }}> <i>{productIdError}</i> </p> : ''}
                </FormGroup>
                <FormGroup >
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Khu vực sản phẩm</InputGroupText>
                    </InputGroupAddon>
                    <Input type="select" name="storeAreaId" value={storeAreaId} id="storeAreaId" onChange={this.onChange.bind(this)} >
                      <option value="">-- select --</option>
                      {storearea.length > 0 ? storearea.map(a => {
                        return (< option key={a.id} value={a.id} > {a.name}</option>)
                      }) : ''}

                    </Input>
                    <InputGroupAddon addonType="append">
                      <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {storeAreaIdError ? <p style={{ color: 'red', display: 'table', margin: '0 auto' }}> <i>{storeAreaIdError}</i> </p> : ''}
                </FormGroup>
                <FormGroup className="form-actions">
                  <Button type="submit" size="sm" color="primary">Thêm giá sản phẩm</Button>
                </FormGroup>
              </Form>
            </ModalBody>
          </Modal>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
    listPriceProducts: state.listPriceProducts,
  }
}
export default connect(mapStateToProps)(PriceProducts);