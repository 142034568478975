import React, { Component } from 'react';
import { Alert, Table, Pagination, Form, FormGroup, Input, Label, ModalBody, Card, ModalHeader, PaginationItem, PaginationLink, Col, Row, Button } from 'reactstrap';
import { api } from '../../../views'
import moment from 'moment'
import qs from 'query-string'
import '../../../index.css'

class StoreTurnOver extends Component {

  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      count: 0,
      prev: 0,
      next: 2,
      last: 0,
      first: 1,
      currentPage: 1,
      pagesNumber: [],
      large: false,
      fromDate: '',
      toDate: '',
      storeId: '',
      roleId: 0,
      listStore: []
    };
  }
  async handleClick(event) {
    let search = '?'
    let fromDate = qs.parse(this.props.location.search).fromDate;
    let toDate = qs.parse(this.props.location.search).toDate;
    let storeId = qs.parse(this.props.location.search).storeId;
    if (fromDate) {
      search += '&fromDate=' + fromDate
    }
    if (toDate) {
      search += '&toDate=' + toDate
    }
    if (storeId) {
      search += '&storeId=' + storeId
    }
    if (event) {
      search += '&page=' + event;
    }
    await this.props.history.push(search)
    this.actGetStoreTurnOvers(fromDate, toDate, this.state.listStore)
  }

  async componentDidMount() {
    await this.actGetUsers()
    if (this.state.listStore) {
      if (!this.state.listStore.toString() && localStorage.getItem('roleId').split(',')[0] == 5) {
        this.setState({
          datas: ''
        })
      }
      else {
        this.actGetStoreTurnOvers("", "", this.state.listStore)
      }
    }
    // this.filterStore()
  }

  async actGetUsers() {
    var id = localStorage.getItem('userId')
    // console.log('id', id)
    var result = await api.dataService.actFetchUsersRequest('', 10, '', { id: id }, '')
    console.log('uuu', result)
    if (result.data.length === 1)
      this.setState({
        listStore: result.data[0].listStore
      })
  }

  // filterStore() {
  //   let filteredStore = this.state.datas.filter(s => {
  //     this.state.listStore.find(storeToFilter => {
  //       console.log("storeid", s.storeId)
  //       console.log("id", storeToFilter)
  //       return s.storeId === storeToFilter
  //     })
  //   })
  //   this.setState({
  //     datas: filteredStore
  //   })
  // }

  async exportExcell(fromDate, toDate, storeId, listStore) {
    if (listStore.toString()) {
      var result = await api.dataService.actGetStoreTurnOverExportExcell(fromDate, toDate, listStore, localStorage.getItem('RFTK-GK'))
    }
    else {
      var result = await api.dataService.actGetStoreTurnOverExportExcell(fromDate, toDate, storeId, localStorage.getItem('RFTK-GK'))
    }
    console.log({ result })
    if (result.status !== 200) {
      alert(
        result.data.message || "Đã có lỗi xảy ra, Xin vui lòng thử lại sau!"
      );
    }
  }
  async actGetStoreTurnOvers(fromDate, toDate, listStore) {

    let urlQueryObj = qs.parse(this.props.location.search);
    let currentPage = Number(urlQueryObj.page || 1);
    if (isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let limit = urlQueryObj.limit || 10;
    let skip = (currentPage - 1) * limit;
    let where = listStore;
    var result = await api.dataService.actGetStoreTurnOver(skip, limit, toDate, fromDate, where)
    // if (where) {
    //   let where2 = where.toString();
    //   var result = await api.dataService.actGetStoreTurnOver(skip, limit, toDate, fromDate, where2);
    // }
    // else {

    // }
    let count = result.data.count;
    let maxPage = Math.ceil(count / limit);
    //console.log({maxPage});
    //console.log({currentPage});

    let pagesNumber = [];

    if (maxPage <= 5) {
      for (let i = 0; i < maxPage; i++) {
        pagesNumber.push(i + 1);
      }

    }
    else if (maxPage > 5 && maxPage - 2 <= currentPage) {
      pagesNumber = [0, maxPage - 4, maxPage - 3, maxPage - 2, maxPage - 1, maxPage];
    } else if (currentPage < 4) {
      pagesNumber = [1, 2, 3, 4, 5];
      if (maxPage > 5) {
        pagesNumber.push(0);
      }
    } else {
      pagesNumber = [0, currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2, 0];
    }
    let next = currentPage + 1;

    let prev = currentPage - 1;

    this.setState({ datas: result.data.data, next, prev, count, currentPage, last: maxPage || 1, pagesNumber });

  }
  formatCurrency(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }
  onChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value
    });
  }
  onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      this.onSubmitSearch();
    }
  }
  onSubmitSearch = async e => {
    e.preventDefault();
    e.target.reset()
    var { fromDate, toDate, storeId, listStore } = this.state;
    if (fromDate > toDate) {
      return api.api.showMessage('Ngày bắt đầu phải lớn hơn ngày kết thúc ! Vui lòng kiểm tra lại')
    }
    this.setState({ currentPage: 1 })
    await this.props.history.push('?page=' + 1 + '&from=' + (fromDate || '') + '&to=' + (toDate || '') + '&storeId=' + (storeId || ''));
    if (listStore.toString() || localStorage.getItem('roleId').split(',')[0] == 5) {
      let searchStore = await listStore.find(e => e == storeId);
      if (searchStore) {
        await this.actGetStoreTurnOvers(fromDate, toDate, searchStore);
      }
      if (searchStore === undefined) {
        this.setState({
          datas: ''
        })
      }
      if (searchStore === undefined && storeId == '' && listStore.toString()) {
        await this.actGetStoreTurnOvers(fromDate, toDate, listStore);
      }
    }
    else {
      let result = this.actGetStoreTurnOvers(fromDate, toDate, storeId);
    }



  }
  render() {
    var { datas, count, currentPage, prev, next, pagesNumber, last, fromDate, toDate, storeId, listStore } = this.state
    console.log("data length", datas.length)
    return (
      <div className="animated fadeIn">
        <Row>
          <Col>
            <Form onSubmit={this.onSubmitSearch}>
              <Row form>

                <Col md={4}>
                  <FormGroup>
                    <Label for="exampleState">ID cửa hàng</Label>
                    <Input type="text" id="storeId" name="storeId" value={storeId} onChange={this.onChange.bind(this)} />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="exampleCity">Từ ngày</Label>
                    <Input type="date" name="fromDate" id="fromDate" value={fromDate} onChange={this.onChange.bind(this)} placeholder="date placeholder" />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="exampleCity">Đến ngày</Label>
                    <Input type="date" name="toDate" id="toDate" value={toDate} onChange={this.onChange.bind(this)} placeholder="date placeholder" />
                  </FormGroup>
                </Col>
              </Row>
              {/* <FormGroup className="form-actions">
                <Button type="submit" size="sm" color="primary">Tìm kiếm</Button>
              </FormGroup> */}
              {
                datas.length > 0 ?
                  (<FormGroup className="form-actions">
                    <Button type="submit" size="sm" color="primary">Tìm kiếm</Button>
                    <Button type="submit" className='mr-auto float-right' size="sm" color="success" onClick={() => this.exportExcell(fromDate, toDate, storeId, listStore)}>Xuất file excel</Button>
                  </FormGroup>) : (<FormGroup className="form-actions">
                    <Button type="submit" size="sm" color="primary">Tìm kiếm</Button>
                  </FormGroup>)
              }

            </Form>
            <Alert color="warning">
              <i className="fa fa-align-justify"></i> {datas.length > 0 ? `Dữ liệu (${count} mục)` : 'Đang xử lý ...'}
            </Alert>
            {datas.length > 0 ?
              <Card>
                <Table hover responsive className="table-outline mb-0 d-none d-sm-table">
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center"><i className="icon-people"></i></th>
                      <th>Thông tin cửa hàng</th>
                      <th className="text-left">Doanh số thu nhập</th>
                      <th>Thông tin giảm giá</th>
                      <th className="text-center">Tiền cho người dùng LikeWash</th>
                      <th className="text-center">Tổng hóa đơn</th>
                    </tr>
                  </thead>
                  <tbody>
                    {datas.map((a, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center">

                            <span >{index + 1}</span>
                          </td>
                          <td className="text-left">
                            <div className="small ">
                              <span className="bold">Tên</span> | {a.storeName}
                            </div>
                            <div className="small ">
                              <span className="bold">SĐT  </span>| <span >{a.storePhone}</span>
                            </div>
                            <div className="small ">
                              <span className="bold">Tài khoản</span> | {a.storeAccount}
                            </div>
                            <div className="small ">
                              <span className="bold">ID</span> | {a.storeId}
                            </div>
                          </td>
                          <td className="text-left">
                            <div className="small ">
                              <span className="bold">Tổng doanh số</span> | {this.formatCurrency(a.doanhSo)} VNĐ
                          </div>
                            <div className="small ">
                              <span className="bold">Doanh thu</span> | {this.formatCurrency(a.doanhThu)} VNĐ
                          </div>
                          </td>
                          <td className="text-left">
                            <div className="small ">
                              <span className="bold">Giảm giá Mpoint</span> | {this.formatCurrency(a.giamGiaMpoint)} VNĐ
                          </div>
                            <div className="small ">
                              <span className="bold">Giảm giá Voucher</span> | {this.formatCurrency(a.giamGiaVoucher)} VNĐ
                          </div>
                            <div className="small ">
                              <span className="bold">Tổng giảm giá</span> | {this.formatCurrency(a.giamGia)} VNĐ
                          </div>
                          </td>
                          <td className="text-center">
                            <div className="bold ">
                              {this.formatCurrency(a.moneyForVoucherUser)} VNĐ
                          </div>

                          </td>
                          <td className="text-center">
                            <div className="clearfix">
                              <div className="small ">
                                <span className="bold" style={{ fontSize: '24px' }}> {a.totalBill}</span>
                              </div>
                            </div>
                          </td>

                        </tr>)
                    })}


                  </tbody>
                </Table> </Card> : <Alert color="warning" className="center">
                Không có dữ liệu !
      </Alert>}
            <div style={{ display: 'table', margin: '0 auto' }}>
              {pagesNumber.length == 1 ? '' : <Pagination >
                <PaginationItem>
                  {prev === 0 ? <PaginationLink className="hide">First</PaginationLink> :
                    <PaginationLink onClick={() => this.handleClick(1)} id="first">First</PaginationLink>
                  }
                </PaginationItem>
                <PaginationItem>
                  {prev < 1 ? <PaginationLink className="hide"><i className="cui-chevron-left icons "></i></PaginationLink> :
                    <PaginationLink onClick={() => this.handleClick(prev)} id="prev"><i className="cui-chevron-left icons "></i></PaginationLink>
                  }
                </PaginationItem>
                {!pagesNumber ? '' : pagesNumber.map((pageNumber, index) => {
                  return (<Pagination key={index}>
                    <PaginationItem active={currentPage == (pageNumber)} >
                      {pageNumber ? <PaginationLink onClick={() => { this.handleClick(pageNumber) }} key={index} >
                        {pageNumber}
                      </PaginationLink> : <PaginationLink className="disabled" onClick={() => { this.handleClick(pageNumber) }} key={index} >
                          ...
                          </PaginationLink>
                      }
                    </PaginationItem>
                  </Pagination>)

                })
                }
                <PaginationItem>
                  {
                    next > last ? <PaginationLink className="hide"><i className="cui-chevron-right icons "></i></PaginationLink> :
                      <PaginationLink onClick={() => this.handleClick(next)} id="next" > <i className="cui-chevron-right icons "></i></PaginationLink>
                  }
                </PaginationItem>
                {localStorage.getItem('roleId').split(',')[0] != 5 && (
                  <PaginationItem>
                    {
                      currentPage === last ? <PaginationLink className="hide">Last</PaginationLink> :
                        <PaginationLink onClick={() => this.handleClick(last)} id="last" >Last</PaginationLink>
                    }
                  </PaginationItem>
                )}
              </Pagination>}

            </div>
          </Col>
        </Row>
      </div >
    );
  }
}

export default StoreTurnOver;
