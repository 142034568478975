
let store = null
let host = null
const api = {

  setStore: (newstore) => {
    store = newstore
  },

  actFecthPartner: (partners) => {
    store.dispatch({type: 'FETCH_PARTNERS', partners})
  },
  actFecthPartnerReport: (partners) => {
    store.dispatch({type: 'FETCH_PARTNERS_REPORT', partners})
  },
  actUpdatePartner: (partner) => {
    store.dispatch({ type: 'UPDATE_PARTNER', partner })
  },

  actFetchUsers: (user) => {
    store.dispatch({ type: 'FETCH_USERS', user })
  },
  actAddUser: (user) => {
    store.dispatch({ type: 'ADD_USER', user })
  },
  actUpdateUser: (user) => {
    // console.log(user)
    store.dispatch({ type: 'UPDATE_USER', user })
  },

  actDeleteUser: (id) => {
    store.dispatch({ type: 'DELETE_USER', id })
  },
  actGetUser: (user) => {
    store.dispatch({ type: 'EDIT_USER', user })
  },

  actFetchBills: (bill) => {
    store.dispatch({ type: 'FETCH_BILL', bill })
  },
  actDeleteBill: (id) => {
    store.dispatch({ type: 'DELETE_BILL', id })
  },

  actFetchStores: (storea) => {
    store.dispatch({ type: 'FETCH_STORES', storea })
  },
  actAddStore: (storea) => {
    store.dispatch({ type: 'ADD_STORE', storea })
  },
  actUpdateStore: (storea) => {
    store.dispatch({ type: 'UPDATE_STORE', storea })  
  },
  actDeleteStore: (id) => {
    store.dispatch({ type: 'DELETE_STORE', id })
  },
  actGetStore: (storea) => {
    store.dispatch({ type: 'EDIT_STORE', storea })
  },

  actFetchStorePartners: (storepartner) => {
    store.dispatch({ type: 'FETCH_STOREPARTNERS', storepartner })
  },
  actAddStorePartner: (storepartner) => {
    store.dispatch({ type: 'ADD_STOREPARTNER', storepartner })
  },
  actUpdateStorePartner: (storepartner) => {
    // console.log(store)
    store.dispatch({ type: 'UPDATE_STOREPARTNER', storepartner })
  },
  actDeleteStorePartner: (id) => {
    store.dispatch({ type: 'DELETE_STOREPARTNER', id })
  },
  actGetStorePartner: (storepartner) => {
    store.dispatch({ type: 'EDIT_STOREPARTNER', storepartner })
  },

  actFetchConfigs: (config) => {
    store.dispatch({ type: 'FETCH_CONFIGS', config })
  },
  // actAddConfig: (storea) => {
  //   store.dispatch({ type: 'ADD_STORE', storea })
  // },
  actUpdateConfig: (config) => {
    // console.log(store)
    store.dispatch({ type: 'UPDATE_CONFIGS', config })
  },
  // actDeleteConfig: (id) => {
  //   store.dispatch({ type: 'DELETE_STORE', id })
  // },
  actGetConfig: (config) => {
    store.dispatch({ type: 'EDIT_CONFIGS', config })
  },

  actFetchStoreAreas: (storearea) => {
    store.dispatch({ type: 'FETCH_STOREAREAS', storearea })
  },
  actAddStoreArea: (storearea) => {
    store.dispatch({ type: 'ADD_STOREAREA', storearea })
  },
  actUpdateStoreArea: (storearea) => {
    // console.log(store)
    store.dispatch({ type: 'UPDATE_STOREAREA', storearea })
  },

  actDeleteStoreArea: (id) => {
    store.dispatch({ type: 'DELETE_STOREAREA', id })
  },
  actGetStoreArea: (storearea) => {
    store.dispatch({ type: 'EDIT_STOREAREA', storearea })
  },

  actFetchProducts: (product) => {
    store.dispatch({ type: 'FETCH_PRODUCTS', product });
    return product
  },
  actAddProduct: (product) => {
    store.dispatch({ type: 'ADD_PRODUCT', product })
  },
  actUpdateProduct: (product) => {
    // console.log(store)
    store.dispatch({ type: 'UPDATE_PRODUCT', product })
  },

  actDeleteProduct: (id) => {
    store.dispatch({ type: 'DELETE_PRODUCT', id })
  },
  actGetProduct: (product) => {
    store.dispatch({ type: 'EDIT_PRODUCT', product })
  },

  actFetchCategories: (category) => {
    store.dispatch({ type: 'FETCH_CATEGORIES', category })
  },
  actAddCategory: (category) => {
    store.dispatch({ type: 'ADD_CATEGORY', category })
  },
  actUpdateCategory: (category) => {
    // console.log(store)
    store.dispatch({ type: 'UPDATE_CATEGORY', category })
  },

  actDeleteCategory: (id) => {
    store.dispatch({ type: 'DELETE_CATEGORY', id })
  },
  actGetCategory: (category) => {
    store.dispatch({ type: 'EDIT_CATEGORY', category })
  },

  actFetchPriceProducts: (priceproduct) => {
    store.dispatch({ type: 'FETCH_PRICEPRODUCTS', priceproduct })
  },
  actAddPriceProduct: (priceproduct) => {
    store.dispatch({ type: 'ADD_PRICEPRODUCT', priceproduct })
  },
  actUpdatePriceProduct: (priceproduct) => {
    // console.log(store)
    store.dispatch({ type: 'UPDATE_PRICEPRODUCT', priceproduct })
  },

  actDeletePriceProduct: (id) => {
    store.dispatch({ type: 'DELETE_PRICEPRODUCT', id })
  },
  actGetPriceProduct: (priceproduct) => {
    store.dispatch({ type: 'EDIT_PRICEPRODUCT', priceproduct })
  },

  actFetchStoreUsers: (storeuser) => {
    store.dispatch({ type: 'FETCH_STOREUSERS', storeuser })
  },
  actAddStoreUser: (storeuser) => {
    store.dispatch({ type: 'ADD_STOREUSER', storeuser })
  },
  actDeleteStoreUser: (id) => {
    store.dispatch({ type: 'DELETE_STOREUSER', id })
  },


  actFetchVouchers: (voucher) => {
    store.dispatch({ type: 'FETCH_VOUCHERS', voucher })
  },
  actAddVoucher: (voucher) => {
    store.dispatch({ type: 'ADD_VOUCHER', voucher })
  },
  actUpdateVoucher: (voucher) => {
    // console.log(store)
    store.dispatch({ type: 'UPDATE_VOUCHER', voucher })
  },

  actGetVoucher: (voucher) => {
    store.dispatch({ type: 'EDIT_VOUCHER', voucher })
  },

  actFetchRatings: (rating) => {
    store.dispatch({ type: 'FETCH_RATINGS', rating })
  },
  actAddRating: (rating) => {
    store.dispatch({ type: 'ADD_RATING', rating })
  },
  actDeleteRating: (id) => {
    store.dispatch({ type: 'DELETE_RATING', id })
  },


  // actGetUserinfo: (userInfo) => {
  //   store.dispatch({ type: 'EDIT_USERINFO', userInfo })
  // },
  setUserToken: (token) => {
    store.dispatch({ type: "SET_USER_TOKEN", token })
  },
  setUserInfo: (user) => {
    store.dispatch({ type: "SET_USER_INFO", data: user })
  },

  showMessage: (message, titleMessage) => {
    store.dispatch({ type: "SHOW_MESSAGE", message, titleMessage })
  },
  hideMessage: () => {
    store.dispatch({ type: "HIDE_MESSAGE" })
  },
  showLoading: () => {
    store.dispatch({ type: "SHOW_LOADING" })
  },
  hideLoading: () => {
    store.dispatch({ type: "HIDE_LOADING" })
  },
  showConfirm: (title, body, bodyConfirmOK, bodyConfirmNO, titleBtnOK, titleBtnNO) => {
    store.dispatch({ type: "SHOW_CONFIRMBOX", title, body, bodyConfirmOK, bodyConfirmNO, titleBtnOK, titleBtnNO })
  },
  hideCofirm: () => {
    store.dispatch({ type: "HIDE_CONFIRMBOX" })
  },
  getToken: () => { return (store && store.getState().userReducer.token) ? store.getState().userReducer.token : "customer" },

  // getLang() {
  //     // if (!store.getState()) return 'en'
  //     // return store.getState().langState.lang
  //     return localStorage.getItem('language') ? localStorage.getItem('language') : 'vi'
  // },
  getHost() {
    return host
  },
  // setCate: (category) => {
  //     store.dispatch({ type: "SET_CATE", category })
  // },
  // setLang: (language) => {
  //     store.dispatch({ type: "SET_LANGUAGE", language })
  // },
  // search: (keyword) => {
  //     store.dispatch({ type: 'SEARCH', keyword })
  // },
  // filterTable: (filter) => {
  //     store.dispatch({ type: 'FILTER_TABLE', filter })
  // },
  // sort: (sort) => {
  //     store.dispatch({ type: 'SORT', sort })
  // },


}
export default api;
