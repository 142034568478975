var initialState = {
  priceproducts: []
}

const listPriceProducts = (state = initialState, action) => {
  let priceproducts = [...state.priceproducts];
  var index = -1;
  var id = '';
  switch (action.type) {
    case 'FETCH_PRICEPRODUCTS':
      // console.log({ action })
      return Object.assign({}, state, { priceproducts: action.priceproduct });
    case 'ADD_PRICEPRODUCT':
      // console.log({ action })
      priceproducts.push(action.priceproduct);
      return { ...state, priceproducts };
    case 'UPDATE_PRICEPRODUCT':
      // console.log({ actionUpdate: action })
      id = action.priceproduct.id;
      index = findIndex(state, { id });
      if (index == -1) {
        priceproducts.push(action.priceproduct);
      } else {
        priceproducts[index] = action.priceproduct
      }
      // console.log('priceproducts', priceproducts)
      return { ...state, priceproducts };
    case 'DELETE_PRICEPRODUCT':
      // console.log({ action })
      index = findIndex(state, action.id);

      priceproducts.splice(index, 1);
      return { ...state, priceproducts };
    default: return state;
  }
}

var findIndex = (priceproducts, id) => {
  let result = -1;
  priceproducts.priceproducts.forEach((priceproduct, index) => {
    if (priceproduct.id === id) {
      result = index;
    }
  });

  return result;
}

export default listPriceProducts;
