var initialState = {
  bills: []
}

const listBill = (state = initialState, action) => {
  let bills = [...state.bills];
  var index = -1;
  switch (action.type) {
    case 'FETCH_BILL':
      // console.log({ action })
      return Object.assign({}, state, { bills: action.bill });
    case 'DELETE_BILL':
      index = findIndex(state, action.id);

      bills.splice(index, 1);
      return { ...state, bills };
    default: return state;
  }
}

var findIndex = (bills, id) => {
  let result = -1;
  bills.bills.forEach((bill, index) => {
    if (bill.id === id) {
      result = index;
    }
  });

  return result;
}

export default listBill;
