import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './App.css';
// Styles
// CoreUI Icons Set
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.css'

// Containers
import { DefaultLayout } from './containers';
// Pages
import { Login, Page404, Page500, CustomerReviews, Registrations,PushNotice, Register, PushNoticeForAppUser,BillCheck } from './views/Pages';
import UI from './views/UI'
// import { renderRoutes } from 'react-router-config';

class App extends Component {
  
  render() {
    return (
      <React.Fragment>
        <UI />
        <HashRouter >
          <Switch>
         
            <Route exact path="/login" name="Login Page" component={Login} />
            <Route exact path="/rating" name="Rating" component={CustomerReviews} />
            <Route exact path="/bill-check" name="BillCheck" component={BillCheck} />
            <Route exact path="/registration" name="Registration" component={Registrations} />
            <Route exact path="/push-notice" name="PushNotice" component={PushNotice} />
            <Route exact path="/push-notice-for-app-user" name="PushNoticeForAppUser" component={PushNoticeForAppUser} />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/register" name="Register Page" component={Register} />
            {/* <Route exact path="/500" name="Page 500" component={Page500} /> */}
            <Route path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </HashRouter>
      </React.Fragment>
    );
  }
}

export default App;
