import React, { Component } from 'react';
import { Alert, Table, FormGroup, Pagination, Card, PaginationItem, PaginationLink, Col, Button, Form, Input, Label, Row } from 'reactstrap';
import { api } from '../../../views'
import moment from 'moment'
import qs from 'query-string'
import '../../../index.css'

class Email extends Component {

  constructor(props) {
    super(props);
    this.state = {
      listEmails: [],
      monthlyEmailFee: '',
      count: 0,
      prev: 0,
      next: 2,
      last: 0,
      first: 1,
      currentPage: 1,
      pagesNumber: []
    };

  }
  async handleClick(event) {
    let search = '?'
    let fromDate = qs.parse(this.props.location.search).fromDate;
    let toDate = qs.parse(this.props.location.search).toDate;
    let storeId = qs.parse(this.props.location.search).storeId;
    if (fromDate) {
      search += '&fromDate=' + fromDate
    }
    if (toDate) {
      search += '&toDate=' + toDate
    }
    if (storeId) {
      search += '&storeId=' + storeId
    }

    if (event) {
      search += '&page=' + event;
    }
    await this.props.history.push(search)

    this.actGetEmail(fromDate, toDate, storeId)
  }

  componentDidMount() {
    this.actGetEmail()
  }
  async actGetEmail(fromDate, toDate, storeId) {

    let urlQueryObj = qs.parse(this.props.location.search);
    let currentPage = Number(urlQueryObj.page || 1);
    if (isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let limit = urlQueryObj.limit || 10;
    let skip = (currentPage - 1) * limit;
    console.log({ fromDate })
    var result = await api.dataService.actGetEmailOrSms(skip, limit, toDate, fromDate, storeId, 'EMAIL')
    // console.log('888', result)
    let count = result.data.count;


    let maxPage = Math.ceil(count / limit);
    //console.log({ maxPage });
    //console.log({ currentPage });

    let pagesNumber = [];

    if (maxPage <= 5) {
      for (let i = 0; i < maxPage; i++) {
        pagesNumber.push(i + 1);
      }

    }
    else if (maxPage > 5 && maxPage - 2 <= currentPage) {
      pagesNumber = [0, maxPage - 4, maxPage - 3, maxPage - 2, maxPage - 1, maxPage];
    } else if (currentPage < 4) {
      pagesNumber = [1, 2, 3, 4, 5];
      if (maxPage > 5) {
        pagesNumber.push(0);
      }
    } else {
      pagesNumber = [0, currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2, 0];
    }
    let next = currentPage + 1;

    let prev = currentPage - 1;

    this.setState({ listEmails: result.data.data, monthlyEmailFee: result.data.monthlyEmailFee, next, prev, count, currentPage, last: maxPage || 1, pagesNumber });

  }
  formatCurrency(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }
  onChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value
    });
  }
  onSubmitSearch = async e => {
    e.preventDefault();
    e.target.reset()
    var { fromDate, toDate, storeId } = this.state;
    if (fromDate > toDate) {
      return api.api.showMessage('Ngày bắt đầu phải lớn hơn ngày kết thúc ! Vui lòng kiểm tra lại')
    }
    this.setState({ currentPage: 1 })
    await this.props.history.push('?page=' + 1 + '&from=' + (fromDate || '') + '&to=' + (toDate || '') + '&storeId=' + (storeId || ''))

    this.actGetEmail(fromDate, toDate, storeId)

  }
  render() {
    var { listEmails, monthlyEmailFee, count, currentPage, prev, next, pagesNumber, last, storeId, toDate, fromDate } = this.state
    return (
      <div className="animated fadeIn">
        <Row>
          <Col>
            <Form onSubmit={this.onSubmitSearch}>
              <Row form>

                <Col md={4}>
                  <FormGroup>
                    <Label for="exampleState">ID cửa hàng</Label>
                    <Input type="text" id="storeId" name="storeId" value={storeId} onChange={this.onChange.bind(this)} />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="exampleCity">Từ ngày</Label>
                    <Input type="date" name="fromDate" id="fromDate" value={fromDate} onChange={this.onChange.bind(this)} placeholder="date placeholder" />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="exampleCity">Đến ngày</Label>
                    <Input type="date" name="toDate" id="toDate" value={toDate} onChange={this.onChange.bind(this)} placeholder="date placeholder" />
                  </FormGroup>
                </Col>

              </Row>
              <FormGroup className="form-actions">
                <Button type="submit" size="sm" color="primary">Tìm kiếm</Button>
              </FormGroup>
            </Form>
            <Alert color="warning">
              <i className="fa fa-align-justify"></i> {listEmails.length > 0 ? `Dữ liệu (${count} mục)` : 'Đang xử lý ...'}
            </Alert>
            <Card>
              <Table hover responsive className="table-condensed mb-0 d-none d-sm-table">
                <thead className="thead-light">
                  <tr>
                    <th className="text-center"><i className="icon-people"></i></th>
                    <th>Tên cửa hàng</th>
                    <th className="text">Địa chỉ cửa hàng</th>
                    <th>Phí dịch vụ</th>
                  </tr>
                </thead>
                <tbody>
                  {listEmails !== "" ? listEmails.map((a, index) => {
                    const url = `#/quanlycuahang/stores/${a.storeId}`
                    return (
                      <tr key={index}>
                        <td className="text-center">

                          <span >{index + 1}</span>
                        </td>
                        <td>
                          <div><a href={url}>{a.storeName}</a> </div>
                          {/* <div className="small text-muted">
                          <span>New</span> | Registered: Jan 1, 2015
                      </div> */}
                        </td>
                        <td className="text-left">
                          <span style={{ fontSize: 13 + 'px' }}>{a.storeAddress}</span>
                        </td>
                        <td>
                          <div className="clearfix">
                            <div className="float-left">
                              <strong>{a.quantity ? this.formatCurrency(monthlyEmailFee) : '0'}</strong>
                            </div>
                            <div className="float-right">
                              <small className="text-muted"> VNĐ</small>
                            </div>
                          </div>
                        </td>

                      </tr>)
                  }) : <Alert color="warning" className="text-center">Không có dữ liệu !</Alert>}


                </tbody>
              </Table> </Card>
            <div style={{ display: 'table', margin: '0 auto' }}>
              {pagesNumber.length == 1 ? '' : <Pagination >
                <PaginationItem>
                  {prev === 0 ? <PaginationLink className="hide">First</PaginationLink> :
                    <PaginationLink onClick={() => this.handleClick(1)} id="first">First</PaginationLink>
                  }
                </PaginationItem>
                <PaginationItem>
                  {prev < 1 ? <PaginationLink className="hide"><i className="cui-chevron-left icons "></i></PaginationLink> :
                    <PaginationLink onClick={() => this.handleClick(prev)} id="prev"><i className="cui-chevron-left icons "></i></PaginationLink>
                  }
                </PaginationItem>
                {!pagesNumber ? '' : pagesNumber.map((pageNumber, index) => {
                  return (<Pagination key={index}>
                    <PaginationItem active={currentPage == (pageNumber)} >
                      {pageNumber ? <PaginationLink onClick={() => { this.handleClick(pageNumber) }} key={index} >
                        {pageNumber}
                      </PaginationLink> : <PaginationLink className="disabled" onClick={() => { this.handleClick(pageNumber) }} key={index} >
                          ...
                          </PaginationLink>
                      }
                    </PaginationItem>
                  </Pagination>)

                })
                }

                <PaginationItem>
                  {
                    next > last ? <PaginationLink className="hide"><i className="cui-chevron-right icons "></i></PaginationLink> :
                      <PaginationLink onClick={() => this.handleClick(next)} id="next" > <i className="cui-chevron-right icons "></i></PaginationLink>
                  }
                </PaginationItem>

                <PaginationItem>
                  {
                    currentPage === last ? <PaginationLink className="hide">Last</PaginationLink> :
                      <PaginationLink onClick={() => this.handleClick(last)} id="last" >Last</PaginationLink>
                  }
                </PaginationItem>
              </Pagination>}

            </div>
          </Col>
        </Row>
      </div >
    );
  }
}

export default Email;
