
export default {
  items: [
    // {
    //   name: 'Dashboard',
    //   url: '/dashboard',
    //   icon: 'icon-speedometer',
    //   badge: {
    //     variant: 'info',
    //     text: 'NEW',
    //   },
    // },
    // {
    //   title: true,
    //   name: 'Theme',
    //   wrapper: {            // optional wrapper object
    //     element: '',        // required valid HTML5 element tag
    //     attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
    //   },
    //   class: ''             // optional class names space delimited list for title item ex: "text-center"
    // },
    // {
    //   name: 'Colors',
    //   url: '/theme/colors',
    //   icon: 'icon-drop',
    // },
    // {
    //   name: 'Typography',
    //   url: '/theme/typography',
    //   icon: 'icon-pencil',
    // },
    {
      title: true,
      name: 'LikeWash',
      wrapper: {
        element: '',
        attributes: {},
      },
    },
    // {
    //   name: 'Người dùng VKShare',
    //   url: '/quanlynguoidung',
    //   icon: 'cui-user-female',
    //   children: [
    //     {
    //       name: 'Danh sách đã duyệt',
    //       url: '/quanlynguoidung/nguoidungvoucher',
    //       icon: 'cui-book',
    //     },
    //     {
    //       name: 'Danh sách chờ duyệt',
    //       url: '/quanlynguoidung/nguoiduyetvoucher',
    //       icon: 'cui-bookmark',
    //     },
    //     {
    //       name: 'Thống kê thu nhập',
    //       url: '/quanlynguoidung/thongkethunhap',
    //       icon: 'cui-aperture',
    //     },
    //   ],
    // },
    // {
    //   name: 'Quản lý sản phẩm ',
    //   url: '/quanlysanpham',
    //   icon: 'cui-globe',
    //   children: [
    //     {
    //       name: 'Danh sách sản phẩm',
    //       url: '/quanlysanpham/danhsachsanpham',
    //       icon: 'cui-cart',
    //     },
    //     {
    //       name: 'Danh mục sản phẩm',
    //       url: '/quanlysanpham/danhmucsanpham',
    //       icon: 'cui-brush',
    //     },
    //     {
    //       name: 'Giá sản phẩm',
    //       url: '/quanlysanpham/giasanpham',
    //       icon: 'cui-british-pound ',
    //     },

    //   ],
    // },
    // {
    //   name: 'Quản lý cửa hàng',
    //   url: '/quanlycuahang',
    //   icon: 'icon-basket-loaded',
    //   children: [
    //     {
    //       name: 'Danh sách cửa hàng',
    //       url: '/quanlycuahang/stores',
    //       icon: 'cui-social-spotify',

    //     },
    //     {
    //       name: 'Khu vực cửa hàng',
    //       url: '/quanlycuahang/storesarea',
    //       icon: 'cui-clipboard',

    //     },
    //     {
    //       name: 'Quản lý xưởng',
    //       url: '/quanlycuahang/storepartner',
    //       icon: 'cui-basket-loaded',

    //     },
    //   ]
    // },
    // {
    //   name: 'Quản lý Voucher',
    //   url: '/voucher',
    //   icon: 'cui-layers',
    //   children: [
    //     {
    //       name: 'Danh sách voucher',
    //       url: '/voucher/danhsachvoucher',
    //       icon: 'cui-note',
    //     },
    //   ],
    // },
    // {
    //   name: 'Hóa đơn',
    //   url: '/bill',
    //   icon: 'cui-layers',
    //   children: [
    //     {
    //       name: 'Danh sách hóa đơn',
    //       url: '/bill/view',
    //       icon: 'cui-note',
    //     },
    //     {
    //       name: 'Hóa đơn hủy',
    //       url: '/bill/bill-cancel',
    //       icon: 'cui-note',
    //     },
    //   ],
    // },
    {
      name: 'Thông tin người dùng',
      url: '/user',
      icon: 'icon-people',
      children: [
        {
          name: 'Hồ sơ cá nhân',
          url: '/user/profile',
          icon: 'icon-star',
          badge: {
            variant: 'info',
            text: 'NEW',
          },
        },
      ],
    },
    {
      name: 'Báo cáo',
      url: '/report',
      icon: 'cui-graph',
      children: [
        // {
        //   name: 'Báo cáo nợ',
        //   url: '/report/debt',
        //   icon: 'cui-aperture',
        // },
        // {
        //   name: 'Thống kê Email',
        //   url: '/report/email',
        //   icon: 'cui-ban',
        // },
        // {
        //   name: 'Thống kê SMS',
        //   url: '/report/sms',
        //   icon: 'cui-badge',
        // },
        // {
        //   name: 'Lượng hóa đơn tự xử lý',
        //   url: '/report/finish-work',
        //   icon: 'icon-bell',
        // },
        // {
        //   name: 'Doanh số nhân viên',
        //   url: '/report/turnover',
        //   icon: 'icon-directions',
        // },
        // {
        //   name: 'Doanh số chuyển xưởng',
        //   url: '/report/partner-store/turnover',
        //   icon: 'cui-bolt',
        // },
        {
          name: 'Doanh số cửa hàng',
          url: '/report/store/turnover',
          icon: 'cui-bolt',
        },
        // {
        //   name: 'Danh sách tăng ca',
        //   url: '/report/overtime',
        //   icon: 'cui-briefcase',
        // },
        {
          name: 'Đánh giá người dùng',
          url: '/report/rating',
          icon: 'cui-briefcase',
        },
        // {
        //   name: 'Doanh số nhận xét',
        //   url: '/report/ratingStar',
        //   icon: 'cui-briefcase',
        // },
        // {
        //   name: 'Doanh số kiểu đánh giá',
        //   url: '/report/ratingType',
        //   icon: 'cui-briefcase',
        // },
        // {
        //   name: 'Modals',
        //   url: '/report/modals',
        //   icon: 'icon-bell',
        // },
      ],
    },
     {
          name: 'Mật khẩu người dùng',
          url: '/password/changepassword',
          icon: 'cui-speedometer',
        },
    // {
    //   name: 'Duyệt giá sản phẩm',
    //   url: '/specific',
    //   icon: 'cui-screen-desktop',
    //   children: [
    //     {
    //       name: 'Sản phẩm đã duyệt',
    //       url: '/specific/specific-approved',
    //       icon: 'cui-action-redo',
    //     },
    //     {
    //       name: 'Sản phẩm chờ duyệt',
    //       url: '/specific/specific-pending',
    //       icon: 'icon-bell',
    //     },
    //   ],
    // },
    // {
    //   name: 'Đánh giá nhận xét',
    //   url: '/rating-reviews',
    //   icon: 'cui-layers',
    //   children: [
    //     {
    //       name: 'Danh sách đánh giá',
    //       url: '/rating-reviews/views',
    //       icon: 'cui-note',
    //     },
    //   ],
    // },
    // {
    //   name: 'Cấu hình hệ thống',
    //   url: '/config',
    //   icon: 'cui-list',
    //   children: [
    //     {
    //       name: 'Cấu hình hệ thống',
    //       url: '/config/configmanagament',
    //       icon: 'cui-options',
    //     },
    //   ],
    // },
    // {
    //   name: 'Thay đổi mật khẩu',
    //   url: '/password',
    //   icon: 'cui-settings',
    //   children: [

    //     {
    //       name: 'Mật khẩu người dùng',
    //       url: '/password/changepassword',
    //       icon: 'cui-speedometer',
    //     },
    //     {
    //       name: 'mật khẩu cửa hàng',
    //       url: '/password/changepasswordstore',
    //       icon: 'cui-pencil',
    //     },
    //     {
    //       name: 'Khôi phục mật khẩu',
    //       url: '/password/resetpassword',
    //       icon: 'cui-people',
    //     },

    //   ],
    // },
    // {
    //   name: 'Widgets',
    //   url: '/widgets',
    //   icon: 'icon-calculator',
    //   badge: {
    //     variant: 'info',
    //     text: 'NEW',
    //   },
    // },
    {
      divider: true,
    },
    // {
    //   title: true,
    //   name: 'ĐĂNG NHẬP VÀ BẢO MẬT',
    // },
    // {
    //   name: 'ĐĂNG NHẬP',
    //   url: '/pages',
    //   icon: 'cui-user',
    //   children: [
    //     {
    //       name: 'Login',
    //       url: '/login',
    //       icon: 'cui-user',
    //     },

    //   ],
    // },
    // {
    //   name: 'Wash Admin',
    //   url: '',
    //   icon: 'icon-cloud-download',
    //   class: 'mt-auto',
    //   variant: 'success',
    // },
    // {
    //   name: 'Download CoreUI',
    //   url: 'http://coreui.io/react/',
    //   icon: 'icon-cloud-download',
    //   class: 'mt-auto',
    //   variant: 'success',
    // },
    // {
    //   name: 'Try CoreUI PRO',
    //   url: 'http://coreui.io/pro/react/',
    //   icon: 'icon-layers',
    //   variant: 'danger',
    // },
  ],
};
