import { api, config } from "../../api";
import axios from "axios";
import moment from "moment";
const request = {
  callApi: async (endpoint, apiVersion, body, method = "GET", token = null) => {
    try {
      token =
        token ||
        (localStorage.getItem("RFTK-GK")
          ? localStorage.getItem("RFTK-GK")
          : api.getToken());

      let option = {
        method,
        url: `${process.env.REACT_APP_HOST ||config.HOST}/${endpoint}`,

        data: body,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // 'Authorization': token,
          Authorization:
            "bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc0RlbGV0ZSI6ZmFsc2UsImNyZWF0ZWRBdCI6MTU0MzI0Mzc3MDc4MywidXBkYXRlZEF0IjoxNTQzMjQzNzcwNzgzLCJkZWxldGVBdCI6MCwiYWNjb3VudCI6InN5c19hZG1pbiIsInBob25lIjoiIiwiZW1haWwiOiIiLCJsb2NrZWQiOmZhbHNlLCJmdWxsTmFtZSI6InVzZXIgNjogc3lzdGVtIGFkbWluIiwiZG9iIjoiIiwiZ2VuZGVyIjoib3RoZXIiLCJpZGVudGl0eUNhcmQiOiIiLCJhZGRyZXNzIjoiIiwiY29tcGFueU5hbWUiOiIiLCJjb21wYW55QWRkcmVzcyI6IiIsImRlc2NyaXB0aW9uIjoiIiwiaXNWZXJpZnkiOnRydWUsImN1cnJlbnRCYWxhbmNlIjowLCJwdXNoTm90aWNlVG9rZW4iOltdLCJiYW5rQWNjb3VudE51bWJlciI6IiIsImJhbmtBY2NvdW50TmFtZSI6IiIsImJhbmtOYW1lIjoiIiwiYmFua0JyYW5kIjoiIiwibGlzdFN0b3JlIjpbXSwiY3JlYXRlZEJ5IjpudWxsLCJkZWxldGVCeSI6bnVsbCwiYXZhdGFyIjoiaHR0cHM6Ly9zZWN1cmUuZ3JhdmF0YXIuY29tL2F2YXRhci9kNGQwNGU1YjIwNzUwN2Q0NTJjNmFiMDI5ZTQ1NTQ0Zj9zPTUwJnI9ZyZkPWlkZW50aWNvbiIsInR5cGUiOiJ1c2VyIiwidXNlcklkIjo2LCJyb2xlSWQiOls3LDFdfQ._DgfPSN5_CbGjwahZR__ATXzrsggdWvsoKdft1My6Zk",
          // 'Accept-Language': api.getLang(),
          "Api-Version": apiVersion,
        },
      };
      console.log({ option: option });
      // console.log('body data pst',)
      let response = await axios(option);

      // if (response.headers['new-token']) {
      //     //TODO: update token localstorage
      //     console.log("Received new token: ", response.headers['new-token'])
      //     localStorage.setItem('RFTK-GK')
      console.log(
        "---------------------------RESPONSE------------------------------\n",
        "option =>",
        option,
        "\n data ===>",
        response
      );
      // }
      return response;
    } catch (error) {
      // let response = await axios(option);
      // throw error.response;
      return error.response;
      // console.log(error.response)
      // console.log(error.response)
    }
  },
  callApi2: async (
    endpoint,
    apiVersion,
    body,
    method = "GET",
    token = null
  ) => {
    try {
      token =
        token ||
        (localStorage.getItem("RFTK-GK")
          ? localStorage.getItem("RFTK-GK")
          : api.getToken());

      let option = {
        method,
        url: `${process.env.REACT_APP_HOST ||config.HOST}/${endpoint}`,

        data: body,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // 'Authorization': token,
          Authorization:
            "bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc0RlbGV0ZSI6ZmFsc2UsImNyZWF0ZWRBdCI6MTU0MzI0Mzc3MDc4MywidXBkYXRlZEF0IjoxNTQzMjQzNzcwNzgzLCJkZWxldGVBdCI6MCwiYWNjb3VudCI6InN5c19hZG1pbiIsInBob25lIjoiIiwiZW1haWwiOiIiLCJsb2NrZWQiOmZhbHNlLCJmdWxsTmFtZSI6InVzZXIgNjogc3lzdGVtIGFkbWluIiwiZG9iIjoiIiwiZ2VuZGVyIjoib3RoZXIiLCJpZGVudGl0eUNhcmQiOiIiLCJhZGRyZXNzIjoiIiwiY29tcGFueU5hbWUiOiIiLCJjb21wYW55QWRkcmVzcyI6IiIsImRlc2NyaXB0aW9uIjoiIiwiaXNWZXJpZnkiOnRydWUsImN1cnJlbnRCYWxhbmNlIjowLCJwdXNoTm90aWNlVG9rZW4iOltdLCJiYW5rQWNjb3VudE51bWJlciI6IiIsImJhbmtBY2NvdW50TmFtZSI6IiIsImJhbmtOYW1lIjoiIiwiYmFua0JyYW5kIjoiIiwibGlzdFN0b3JlIjpbXSwiY3JlYXRlZEJ5IjpudWxsLCJkZWxldGVCeSI6bnVsbCwiYXZhdGFyIjoiaHR0cHM6Ly9zZWN1cmUuZ3JhdmF0YXIuY29tL2F2YXRhci9kNGQwNGU1YjIwNzUwN2Q0NTJjNmFiMDI5ZTQ1NTQ0Zj9zPTUwJnI9ZyZkPWlkZW50aWNvbiIsInR5cGUiOiJ1c2VyIiwidXNlcklkIjo2LCJyb2xlSWQiOls3LDFdfQ._DgfPSN5_CbGjwahZR__ATXzrsggdWvsoKdft1My6Zk",

          // 'Authorization': "bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc0RlbGV0ZSI6ZmFsc2UsImNyZWF0ZWRBdCI6MTU0MzI0Mzc3MDc4MywidXBkYXRlZEF0IjoxNTcyMzE2ODU2NDE5LCJkZWxldGVBdCI6MSwiYWNjb3VudCI6InN0b3JlX3VzZXIiLCJwaG9uZSI6IiIsImVtYWlsIjoiIiwibG9ja2VkIjpmYWxzZSwiZnVsbE5hbWUiOiJ1c2VyIDI6IG5ow6JuIHZpw6puIGPhu61hIGjDoG5nIiwiZG9iIjoiIiwiZ2VuZGVyIjoib3RoZXIiLCJpZGVudGl0eUNhcmQiOiIiLCJhZGRyZXNzIjoiIiwiY29tcGFueU5hbWUiOiIiLCJjb21wYW55QWRkcmVzcyI6IiIsImRlc2NyaXB0aW9uIjoiIiwiaXNWZXJpZnkiOnRydWUsImN1cnJlbnRCYWxhbmNlIjowLCJwdXNoTm90aWNlVG9rZW4iOltdLCJiYW5rQWNjb3VudE51bWJlciI6IiIsImJhbmtBY2NvdW50TmFtZSI6IiIsImJhbmtOYW1lIjoiIiwiYmFua0JyYW5kIjoiIiwibGlzdFN0b3JlIjpbXSwiY3JlYXRlZEJ5IjozLCJkZWxldGVCeSI6bnVsbCwiYXZhdGFyIjoiaHR0cHM6Ly9zZWN1cmUuZ3JhdmF0YXIuY29tL2F2YXRhci9kNGQwNGU1YjIwNzUwN2Q0NTJjNmFiMDI5ZTQ1NTQ0Zj9zPTUwJnI9ZyZkPWlkZW50aWNvbiIsInVzZXJJZCI6MiwidHlwZSI6InVzZXJfc3RvcmUiLCJzdG9yZUlkIjoxLCJleHAiOjE2NTkxMTQwMDAwMDAsInJvbGVJZCI6WzMsMV19.1uAqpQEm23V4eYYew4AV9wskNFpPdCDG1iisD8GV_s8",

          // 'Accept-Language': api.getLang(),
          "Api-Version": apiVersion,
        },
      };
      console.log({ option: option });
      // console.log('body data pst',)
      let response = await axios(option);

      // if (response.headers['new-token']) {
      //     //TODO: update token localstorage
      //     console.log("Received new token: ", response.headers['new-token'])
      //     localStorage.setItem('RFTK-GK')
      console.log(
        "---------------------------RESPONSE------------------------------\n",
        "option =>",
        option,
        "\n data ===>",
        response
      );
      // }
      return response;
    } catch (error) {
      // let response = await axios(option);
      // throw error.response;
      return error.response;
      // console.log(error.response)
      // console.log(error.response)
    }
  },
  upLoad: async (
    endpoint,
    apiVersion,
    method = "POST",
    token = null,
    formData
  ) => {
    try {
      token =
        token ||
        (localStorage.getItem("RFTK-GK")
          ? localStorage.getItem("RFTK-GK")
          : api.getToken());
      let option = {
        url: `${process.env.REACT_APP_HOST ||config.HOST}/${endpoint}`,
        method, // or 'PUT'
        body: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: token,
          "Api-Version": apiVersion,
        },
      };
      console.log({ option });
      let response = await axios(option);
      return response;
    } catch (error) {
      return error.response;
    }
  },
  report: async (url, apiVersion) => {
    try {
      let token = localStorage.getItem("RFTK-GK")
        ? localStorage.getItem("RFTK-GK")
        : api.getToken();
      let options = {
        url: `${process.env.REACT_APP_HOST ||config.HOST}/${url}`,
        method: "GET",
        responseType: "blob", // important
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          "api-version": apiVersion,
        },
      };
      let response = await axios(options);
      console.log({ options });
      console.log({ response });
      url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url; //Custom-File-Name
      link.setAttribute(
        "download",
        `report_${moment().format("DDMMYYHHmm")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      return response;
    } catch (error) {
      return error.response || error;
    }
  },

  report2: async (url, apiVersion) => {
    try {
      let token = localStorage.getItem("RFTK-GK")
        ? localStorage.getItem("RFTK-GK")
        : api.getToken();
      let options = {
        url: `${process.env.REACT_APP_HOST ||config.HOST}/${url}`,
        method: "GET",
        responseType: "blob", // important
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc0RlbGV0ZSI6ZmFsc2UsImNyZWF0ZWRBdCI6MTU0MzI0Mzc3MDc4MywidXBkYXRlZEF0IjoxNTQzMjQzNzcwNzgzLCJkZWxldGVBdCI6MCwiYWNjb3VudCI6InN5c19hZG1pbiIsInBob25lIjoiIiwiZW1haWwiOiIiLCJsb2NrZWQiOmZhbHNlLCJmdWxsTmFtZSI6InVzZXIgNjogc3lzdGVtIGFkbWluIiwiZG9iIjoiIiwiZ2VuZGVyIjoib3RoZXIiLCJpZGVudGl0eUNhcmQiOiIiLCJhZGRyZXNzIjoiIiwiY29tcGFueU5hbWUiOiIiLCJjb21wYW55QWRkcmVzcyI6IiIsImRlc2NyaXB0aW9uIjoiIiwiaXNWZXJpZnkiOnRydWUsImN1cnJlbnRCYWxhbmNlIjowLCJwdXNoTm90aWNlVG9rZW4iOltdLCJiYW5rQWNjb3VudE51bWJlciI6IiIsImJhbmtBY2NvdW50TmFtZSI6IiIsImJhbmtOYW1lIjoiIiwiYmFua0JyYW5kIjoiIiwibGlzdFN0b3JlIjpbXSwiY3JlYXRlZEJ5IjpudWxsLCJkZWxldGVCeSI6bnVsbCwiYXZhdGFyIjoiaHR0cHM6Ly9zZWN1cmUuZ3JhdmF0YXIuY29tL2F2YXRhci9kNGQwNGU1YjIwNzUwN2Q0NTJjNmFiMDI5ZTQ1NTQ0Zj9zPTUwJnI9ZyZkPWlkZW50aWNvbiIsInR5cGUiOiJ1c2VyIiwidXNlcklkIjo2LCJyb2xlSWQiOls3LDFdfQ._DgfPSN5_CbGjwahZR__ATXzrsggdWvsoKdft1My6Zk",
          "api-version": apiVersion,
        },
      };
      let response = await axios(options);
      console.log({ options });
      console.log({ response });
      url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url; //Custom-File-Name
      link.setAttribute(
        "download",
        `report_${moment().format("DDMMYYHHmm")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      return response;
    } catch (error) {
      return error.response || error;
    }
  },
};

// .replace(/\"/g, '')
// .replace(/\"/g, '')
export default request;
