var initialState = {
  ratings: []
}

const ratingReviews = (state = initialState, action) => {
  let ratings = [...state.ratings];
  var index = -1;
  var id = '';
  switch (action.type) {
    case 'FETCH_RATINGS':
      // console.log({ action })
      return Object.assign({}, state, { ratings: action.rating });
    case 'ADD_RATING':
      // console.log({ action })
      ratings.push(action.rating);
      return { ...state, ratings };
    case 'DELETE_RATING':
      // console.log({ action })
      index = findIndex(state, action.id);

      ratings.splice(index, 1);
      return { ...state, ratings };
    default: return state;
  }
}

var findIndex = (ratings, id) => {
  let result = -1;
  ratings.ratings.forEach((rating, index) => {
    if (rating.id === id) {
      result = index;
    }
  });

  return result;
}

export default ratingReviews;
