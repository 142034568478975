import { api, request } from '../../api';
import moment from 'moment'
import axios from 'axios';
const queryString = require('query-string');
var dataService = {
  login(account, password, type) {
    // let urlFacebook = 'api/auth/sign-in/facebook-access-token'
    // let urlGoggle = 'api/auth/sign-in/google-access-token'
    // let urlAccountkit = 'api/auth/sign-in/sms-account-kit'
    let url = 'api/auth/sign-in/user'
    let urlRFTK = 'api/auth/sign-in/refresh-token'
    let urlLogout = 'api/auth/logout'
    let data

    if (type === 'local') {
      data = {
        type,
        account: account,
        password: password
      }
      return request.callApi(url, null, data, 'POST', null).then(res => {
        // console.log('xxx', res)
        if (res.status === 200) {
          return res
        }
        else {
          return (res,
            api.showMessage('Tài khoản người dùng không tồn tại ! Vui lòng thử lại', 'Thông báo'))

        }
      })
    }
    if (type === 'token') {
      data = {
        type,
      }
      return request.callApi(urlRFTK, null, data, 'POST', null)
    }
  },
  logout() {
    let url = 'api/auth/logout'
    return request.callApi(url, 1, null, 'POST', null).then(res => {
      if (res.status === 200) {
        localStorage.clear()
        return res
      }
    })
  },

  getCategoryApp: (parentid, dept) => {
    let url = 'api/categoryapp'
    if (parentid) {
      url += '?parentid=' + (parentid);
    } else {
      url += '?'
    }
    if (dept) {
      url += '&dept=' + (dept);
    }
    return request.callApi(url, 5, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },
  getCategoryAppId: (id) => {
    let url = 'api/categoryapp'
    if (id) {
      url += '?id=' + (id);
    }
    return request.callApi(url, 5, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },
  addNewCategoryApp: (parentid, dept, category) => {
    let url = 'api/categoryapp'
    if (parentid) {
      url += '?parentid=' + (parentid);
    } else {
      url += '?'
    }
    if (dept) {
      url += '&dept=' + (dept);
    }
    return request.callApi(url, 5, category, 'POST', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },
  deleteCategoryApp: (id) => {
    let url = 'api/categoryapp'
    if (id) {
      url += '/' + (id);
    }
    return request.callApi(url, 5, null, 'DELETE', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },
  updateCategoryApp: (id, category) => {
    let url = 'api/categoryapp'
    if (id) {
      url += '/' + (id);
    }
    return request.callApi(url, 5, category, 'PATCH', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actUpload: (formData) => {
    let url = 'api/voucher-user-management/import-list-paid'
    return request.upLoad(url, 5, 'POST', null, formData).then(res => {
      console.log('aaa', res)
      if (res.status === 200) {
        return res
      }
    })
  },
  actFetchBill: (skip, limit, select, where, sort) => {
    let url = 'api/bill'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }
    url += '&populate=' + 'createdBy,storeId';

    return request.callApi(url, 5, null, 'GET', null).then(res => {
      // console.log('yyy', res)
      if (res.status === 200) {
        api.actFetchBills(res.data.data)
        return res.data
      }
      // console.log('yyy', res)
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actFetchVoucherLog: (skip, limit, select, where, sort) => {
    let url = 'api/voucherLog'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }
    url += '&populate=' + 'voucherId,voucherUserId,billId';

    return request.callApi(url, 5, null, 'GET', null).then(res => {
      // console.log('yyy', res)
      if (res.status === 200) {
        return res
      }
      // console.log('yyy', res)
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actFetchvoucherUserWithDraw: (skip, limit, select, where, sort) => {
    let url = 'api/voucherUserWithDraw'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }
    url += '&populate=' + 'createdBy,userId';

    return request.callApi(url, 5, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actFetchUsersRequest: (skip, limit, select, where, sort) => {
    let url = 'api/user'

    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }
    url += '&populate=' + 'avatar';

    return request.callApi(url, 1, null, 'GET', null).then(res => {
      if (res.status === 200) {
        api.actFetchUsers(res.data.data)
        return res.data
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Quý khách thông cảm !', 'Thông báo')
      }
    })
  },

  actFetchStorePartnerRequest: (skip, limit, select, where, sort) => {
    let url = 'api/storePartner'

    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }

    return request.callApi(url, 1, null, 'GET', null).then(res => {
      if (res.status === 200) {
        api.actFetchStorePartners(res.data.data)
        return res.data
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actFetchStoreUsersRequest: (skip, limit, select, where, sort) => {
    let url = 'api/storeUser'

    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }
    url += '&populate=' + 'userId,storeId';

    return request.callApi(url, 4, null, 'GET', null).then(res => {
      if (res.status === 200) {
        api.actFetchStoreUsers(res.data.data)
        return res.data
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },
  actFetchStoreUser: (skip, limit, select, where, sort) => {
    let url = 'api/storeUser'

    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }
    url += '&populate=' + 'userId,storeId';

    return request.callApi(url, 4, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actFetchRatingRequest: (skip, limit, select, where, sort) => {
    let url = 'api/rating'

    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }

    return request.callApi(url, 'public', null, 'GET', null).then(res => {
      if (res.status === 200) {
        api.actFetchRatings(res.data.data)
        return res.data
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actFetchRating: (skip, limit, select, where, sort) => {
    let url = 'api/rating'

    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }

    return request.callApi(url, 'public', null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actGetDebts: (skip, limit, storeId, userId) => {
    let url = 'api/report/debt/debt-by-store-id'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }

    if (storeId) {
      url += '&storeId=' + JSON.stringify(storeId);
    }
    if (userId && userId !== 'undefined') {
      url += '&userId=' + userId
    }

    return request.callApi(url, 3, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actGetRatingByStars: (skip, limit, storeId, from, to) => {
    let url = 'api/report/rating/count-rating-by-star'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }

    if (storeId) {
      url += '&storeId=' + JSON.stringify(storeId);
    }
    if (to) {
      url += '&to=' + moment(to).endOf('days').valueOf();
    } else {
      url += '&to=' + (moment().endOf('month'));
    }
    if (from) {
      url += '&from=' + moment(from).startOf('days').valueOf()
    } else {
      url += '&from=' + (moment().startOf('month'));
    }

    return request.callApi(url, 3, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },


  actGetRatingByTypes: (skip, limit, storeId, from, to) => {
    let url = 'api/report/rating/count-rating-by-type'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }

    if (storeId) {
      url += '&storeId=' + JSON.stringify(storeId);
    }
    if (to) {
      url += '&to=' + moment(to).endOf('days').valueOf();
    } else {
      url += '&to=' + (moment().endOf('month'));
    }
    if (from) {
      url += '&from=' + moment(from).startOf('days').valueOf()
    } else {
      url += '&from=' + (moment().startOf('month'));
    }

    return request.callApi(url, 3, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actGetBillChangeStageLog: (skip, limit, select, where, sort) => {
    let url = 'api/billChangeStageLog'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }
    url += '&populate=' + 'createdBy,storePartnerId,afterStageId,beforeStageId';
    return request.callApi(url, 3, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },


  actGetEmailOrSms: (skip, limit, to, froms, storeId, type, smsType) => {
    let url = 'api/report/notice/sms-email-report'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (to) {
      url += '&to=' + moment(to).endOf('days').valueOf();
    } else {
      url += '&to=' + (moment().endOf('month'));
    }
    if (froms) {
      url += '&from=' + moment(froms).startOf('days').valueOf()
    } else {
      url += '&from=' + (moment().startOf('month'));
    }
    if (storeId && storeId !== 'undefined') {
      url += '&storeId=' + (storeId);
    }
    if (type) {
      url += '&type=' + type
    }
    if (smsType) {
      url += '&smsType=' + smsType
    }
    return request.callApi(url, 4, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actGetWorkFinish: (skip, limit, to, froms, userId, storeId) => {
    let url = 'api/report/staff/amount-of-work-finish'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (to) {
      url += '&to=' + moment(to).endOf('days').valueOf();
    } else {
      url += '&to=' + (moment().endOf('month'));
    }
    if (froms) {
      url += '&from=' + moment(froms).startOf('days').valueOf()
    } else {
      url += '&from=' + (moment().startOf('month'));
    }
    if (storeId && storeId !== 'undefined') {
      url += '&storeId=' + (storeId);
    }
    if (userId && userId !== 'undefined') {
      url += '&userId=' + userId
    }
    return request.callApi(url, 3, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actGetRevenueStructure: (skip, limit, to, froms, id) => {
    let url = 'api/report/bill/detail-report'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?skip=0&'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (id) {
      url += '&storeId=' + (id);
    }
    if (froms) {
      url += '&startDate=' + moment(froms).startOf('days').valueOf()
    } else {
      url += '&startDate=' + (moment().startOf('month'));
    }
    if (to) {
      url += '&endDate=' + moment(to).endOf('days').valueOf();
    } else {
      url += '&endDate=' + (moment().endOf('month'));
    }
    return request.callApi(url, 3, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actGetListCustomersUsingVoucherPartner: (skip, limit, to, froms, partnerId) => {
    let url = 'api/report/voucher-partner/customer-active-voucher-partner'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?skip=0&'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (partnerId && partnerId !== 'undefined') {
      url += '&partnerId=' + (partnerId);
    }
    // else {
    //   url += '&partnerId=3';
    // }
    if (froms) {
      url += '&startDate=' + moment(froms).startOf('days').valueOf()
    } else {
      url += '&startDate=' + (moment().startOf('month'));
    }
    if (to) {
      url += '&endDate=' + moment(to).endOf('days').valueOf();
    } else {
      url += '&endDate=' + (moment().endOf('month'));
    }
    return request.callApi(url, 3, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actGetTurnOver: (skip, limit, to, froms, userId, storeId) => {
    let url = 'api/report/staff/turnover'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (to) {
      url += '&to=' + moment(to).endOf('days').valueOf();
    } else {
      url += '&to=' + (moment().endOf('month'));
    }
    if (froms) {
      url += '&from=' + moment(froms).startOf('days').valueOf()
    } else {
      url += '&from=' + (moment().startOf('month'));
    }
    if (storeId && storeId !== 'undefined') {
      url += '&storeId=' + (storeId);
    }
    if (userId && userId !== 'undefined') {
      url += '&userId=' + userId
    }
    return request.callApi(url, 3, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actGetRating: (skip, limit, to, froms, storeId) => {
    let url = 'api/report/rating'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (to) {
      url += '&to=' + moment(to).endOf('days').valueOf();
    } else {
      url += '&to=' + (moment().endOf('month'));
    }
    if (froms) {
      url += '&from=' + moment(froms).startOf('days').valueOf()
    } else {
      url += '&from=' + (moment().startOf('month'));
    }
    if (storeId && storeId !== 'undefined') {
      url += '&storeId=' + (storeId);
    }
    return request.callApi(url, 4, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },
  actGetStoreTurnOverExportExcell: (froms, to, where, token) => {
    let url = 'api/report/store/turnover?exportExcel=1'
    if (to) {
      url += '&to=' + moment(to).endOf('days').valueOf();
    } else {
      url += '&to=' + (moment().endOf('month').valueOf());
    }
    if (froms) {
      url += '&from=' + moment(froms).startOf('days').valueOf()
    } else {
      url += '&from=' + (moment().startOf('month').valueOf());
    }
    if (where && where !== 'undefined') {
      url += '&where=' + where;
    }
    return request.report(url, 4, token);
  },
  actGetStoreTurnOver: (skip, limit, to, froms, where) => {
    let url = 'api/report/store/turnover'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (to) {
      url += '&to=' + moment(to).endOf('days').valueOf();
    } else {
      url += '&to=' + (moment().endOf('month').valueOf());
    }
    if (froms) {
      url += '&from=' + moment(froms).startOf('days').valueOf()
    } else {
      url += '&from=' + (moment().startOf('month').valueOf());
    }
    if (where && where !== 'undefined') {
      url += '&where=' + where;
    }
    return request.callApi(url, 4, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },
  actGetCustomerUserServiceExportExcell: (cusName, cusPhone, storeName, storeId, token) => {
    let url = 'api/report/customer/customer-using-services?exportExcel=1'
    let where = {};
    if (cusName) {
      where.customerName = { contains: cusName };
    }

    if (cusPhone) {
      where.customerPhone = { contains: cusPhone };
    }

    if (storeId) {
      where.storeId = storeId;
    }
    if (storeName) {
      where.storeName = { contains: storeName };
    }
    url += '&where=' + JSON.stringify(where);
    return request.report(url, 5, token);
  },
  actGetCustomerUserService: (skip, limit, cusName, cusPhone, storeName, storeId) => {
    let url = 'api/report/customer/customer-using-services'
    let where = {};
    if (cusName) {
      where.customerName = { contains: cusName };
    }

    if (cusPhone) {
      where.customerPhone = { contains: cusPhone };
    }

    if (storeId) {
      where.storeId = storeId;
    }
    if (storeName) {
      where.storeName = { contains: storeName };
    }
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    url += '&where=' + JSON.stringify(where);

    return request.callApi(url, 5, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },


  actGetCreateBill: (billId) => {
    let url = 'api/report/list-user-create-bill'
    if (billId) {
      url += '?billId=' + (billId);
    }

    return request.callApi(url, 4, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actGetPartnerTurnOver: (skip, limit, to, froms, storePartnerId, storeId, fillterByStore) => {
    let url = 'api/report/partner-store/turnover'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (to) {
      url += '&to=' + moment(to).endOf('days').valueOf();
    } else {
      url += '&to=' + (moment().endOf('month').valueOf());
    }
    if (froms) {
      url += '&from=' + moment(froms).startOf('days').valueOf()
    } else {
      url += '&from=' + (moment().startOf('month').valueOf());
    }
    if (storeId && storeId !== 'undefined') {
      url += '&storeId=' + (storeId);
    }
    if (storePartnerId) {
      url += '&storePartnerId=' + storePartnerId
    }
    if (fillterByStore) {
      url += '&fillterByStore=' + fillterByStore
    }
    return request.callApi(url, 4, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actGetOverTime: (skip, limit, to, froms, userId, storeId) => {
    let url = 'api/report/staff/overtime'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (to) {
      url += '&to=' + moment(to).endOf('days').valueOf();
    }
    if (froms) {
      url += '&from=' + moment(froms).startOf('days').valueOf()
    }
    if (storeId && storeId !== 'undefined') {
      url += '&storeId=' + (storeId);
    }
    if (userId && userId !== 'undefined') {
      url += '&userId=' + userId
    }
    return request.callApi(url, 3, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actGetBill: (skip, limit, select, where, sort) => {
    let url = 'api/bill'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }
    return request.callApi(url, 3, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actFetchConfigsRequest: (skip, limit, select, where, sort) => {
    let url = 'api/config'

    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }

    return request.callApi(url, 6, null, 'GET', null).then(res => {
      if (res.status === 200) {
        api.actFetchConfigs(res.data.data)
        return res.data
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  // actFetchUsers: (skip, limit, select, where, sort) => {
  //   let url = 'api/user'

  //   if (skip) {
  //     url += '?skip=' + (skip) + '&';
  //   }
  //   else {
  //     url += '?'
  //   }
  //   if (limit) {
  //     url += 'limit=' + (limit);
  //   }
  //   if (select) {
  //     url += '&select=' + (select);
  //   }
  //   if (where) {
  //     url += '&where=' + JSON.stringify(where);
  //   }
  //   if (sort) {
  //     url += '&sort=' + JSON.stringify(sort);
  //   }
  //   url += '&populate=' + 'avatar';

  //   return request.callApi(url, 1, null, 'GET', null).then(res => {
  //     if (res.status === 200) {
  //       return res
  //     }

  //   })
  // },
  actFetchStoresRequest: (skip, limit, select, where, sort) => {
    let url = 'api/store'

    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }
    url += '&populate=storeAreaId';

    return request.callApi(url, 1, null, 'GET', null).then(res => {
      console.log('ggg', res)
      if (res.status === 200) {
        api.actFetchStores(res.data.data)
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actFetchVouchersRequest: (skip, limit, select, where, sort) => {
    let url = 'api/voucher'

    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }
    return request.callApi(url, 5, null, 'GET', null).then(res => {
      if (res.status === 200) {
        api.actFetchVouchers(res.data.data)
        return res.data
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actFetchStoreAreaRequest: (skip, limit, select, where) => {
    let url = 'api/storeArea'

    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    // url += '&populate=' + 'storeAreaId';

    return request.callApi(url, 1, null, 'GET', null).then(res => {
      console.log('ggg', res)
      if (res.status === 200) {
        api.actFetchStoreAreas(res.data.data)
        return res.data
      }
      else {
        api.showMessage('Lỗi hệ thống ! Quý khách vui lòng chờ ít phút')
      }

    })
  },


  actFetchProductsRequest: (skip, limit, select, where, sort, populate) => {
    let url = 'api/product'

    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }

    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }
    if (populate) {
      url += '&populate=' + populate;
    }
    return request.callApi(url, 1, null, 'GET', null).then(res => {
      if (res.status === 200) {
        api.actFetchProducts(res.data.data)
        return res.data;
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actFetchCategoriesRequest: (skip, limit, select, where, sort) => {
    let url = 'api/category'

    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }
    return request.callApi(url, 1, null, 'GET', null).then(res => {
      if (res.status === 200) {
        api.actFetchCategories(res.data.data)
        return res.data
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },


  actFetchPriceProductsRequest: (skip, limit, select, where, sort) => {
    let url = 'api/storeAreaProduct'

    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }
    url += '&populate=' + 'productId,storeAreaId';
    return request.callApi(url, 1, null, 'GET', null).then(res => {
      console.log('ooo', res)
      if (res.status === 200) {
        api.actFetchPriceProducts(res.data.data)
        return res.data
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },


  // actGetUserRequest: (id) => {
  //   let url = `api/user`
  //   return request.callApi(url, 1, id, 'GET', null).then(res => {
  //     console.log({ res })
  //     if (res.status === 200) {
  //       return api.actGetUser(res.data.data)
  //     }
  //     console.log(res.statusText)
  //     api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
  //   })
  // },



  // actGetStoreAreaRequest: (id) => {
  //   let url = `api/storeArea`
  //   return request.callApi(url, 1, id, 'GET', null).then(res => {
  //     console.log({ res })
  //     if (res.status === 200) {
  //       return api.actGetStoreArea(res.data.data)
  //     }
  //     console.log(res.statusText)
  //     api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
  //   })
  // },

  // actGetStoreRequest: (id) => {
  //   let url = `api/store`
  //   return request.callApi(url, 1, id, 'GET', null).then(res => {
  //     console.log({ res })
  //     if (res.status === 200) {
  //       return api.actGetStore(res.data.data)
  //     }
  //     console.log(res.statusText)
  //     api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
  //   })
  // },

  // actGetProductRequest: (id) => {
  //   let url = `api/product`
  //   return request.callApi(url, 1, id, 'GET', null).then(res => {
  //     console.log({ res })
  //     if (res.status === 200) {
  //       return api.actGetProduct(res.data.data)
  //     }
  //     console.log(res.statusText)
  //     api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
  //   })
  // },

  // actGetVoucherRequest: (id) => {
  //   let url = `api/voucher`
  //   return request.callApi(url, 5, id, 'GET', null).then(res => {
  //     console.log({ res })
  //     if (res.status === 200) {
  //       return api.actGetVoucher(res.data.data)
  //     }
  //     console.log(res.statusText)
  //     api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
  //   })
  // },

  // actGetCategoryRequest: (id) => {
  //   let url = `api/category`
  //   return request.callApi(url, 1, id, 'GET', null).then(res => {
  //     console.log({ res })
  //     if (res.status === 200) {
  //       return api.actGetCategory(res.data.data)
  //     }
  //     console.log(res.statusText)
  //     api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
  //   })
  // },

  actAddUserRequest: (user) => {
    let url = 'api/auth/user-management/create-user'
    return request.callApi(url, 4, user, 'POST', null).then(res => {
      if (res.status === 200) {
        return api.actAddUser(res.data)
      }
      // console.log(res.statusText)
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actAddStorePartnerRequest: (storepartner) => {
    let url = 'api/storePartner'
    return request.callApi(url, 5, storepartner, 'POST', null).then(res => {
      if (res.status === 200) {
        return api.actAddStorePartner(res.data)
      }
      // console.log(res.statusText)
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actAddRatingRequest: (rating) => {
    let url = 'api/rating'
    return request.callApi(url, 5, rating, 'POST', null).then(res => {
      if (res.status === 200) {
        return api.actAddRating(res.data)
      }
      // console.log(res.statusText)
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },


  actAddPriceProductRequest: (priceproduct) => {
    let url = 'api/storeAreaProduct'
    return request.callApi(url, 5, priceproduct, 'POST', null).then(res => {
      if (res.status === 200) {
        return api.actAddPriceProduct(res.data)
      }
      // console.log(res.statusText)
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actAddStoreRequest: (store) => {
    let url = 'api/store'
    return request.callApi(url, 5, store, 'POST', null).then(res => {
      if (res.status === 200) {
        return api.actAddStore(res.data)
      }
      // console.log(res.statusText)
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },
  actAddStoreAreaRequest: (storearea) => {
    let url = 'api/storeArea'
    return request.callApi(url, 5, storearea, 'POST', null).then(res => {
      if (res.status === 200) {
        return api.actAddStoreArea(res.data)
      }
      // console.log(res.statusText)
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actAddProductRequest: (product) => {
    let url = 'api/product'
    return request.callApi(url, 5, product, 'POST', null).then(res => {
      if (res.status === 200) {
        return api.actAddProduct(res.data)
      }
      // console.log(res.statusText)
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actAddCategoryRequest: (category) => {
    let url = 'api/category'
    return request.callApi(url, 5, category, 'POST', null).then(res => {
      if (res.status === 200) {
        return api.actAddCategory(res.data)
      }
      // console.log(res.statusText)
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actAddVoucherRequest: (voucher) => {
    let url = 'api/voucher'
    return request.callApi(url, 5, voucher, 'POST', null).then(res => {
      console.log('ttt', res)
      if (res.status === 200) {
        return api.actAddVoucher(res.data)
      }
      // console.log(res.statusText)
      // this.props.history.push('#/400')
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actAddStoreUserRequest: (storeuser) => {
    let url = 'api/store-user-management/add-user-to-store'
    return request.callApi(url, 4, storeuser, 'POST', null).then(res => {
      console.log('ttt', res)
      if (res.status === 200) {
        return res.data
      }
      // console.log(res.statusText)
      // this.props.history.push('#/400')
      api.showMessage("Người dùng hiện đã có trong danh sách cửa hàng !")
    })
  },

  actAddVoucherUser: (voucherUser) => {
    let url = 'api/voucher-user-management/withdraw'
    return request.callApi(url, 5, voucherUser, 'POST', null).then(res => {
      console.log('ttt', res)
      if (res.status === 200) {
        return res
      }
      // console.log(res.statusText)
      // this.props.history.push('#/400')
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },


  actGetRole: (skip, limit, select, where) => {
    let url = 'api/role'

    if (skip) {
      url += '?skip=' + (skip) + '&';
    }

    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }

    return request.callApi(url, 1, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },


  actGetConfig: (skip, limit, select, where) => {
    // if (where) where = ();
    let url = 'api/config'

    if (skip) {
      url += '?skip=' + (skip);
    }
    else {
      url += '?'
    }
    if (limit) {
      url += '&limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    console.log({ where })
    console.log({ url })
    return request.callApi(url, 6, null, 'GET', null).then(res => {
      console.log({ res })
      if (res.status === 200) {
        return res.data
      }
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },



  // actGetStore: (skip, limit, select, where) => {
  //   let url = 'api/store'

  //   if (skip) {
  //     url += '&skip=' + (skip);
  //   }
  //   else {
  //     url += '?'
  //   }
  //   if (limit) {
  //     url += '&limit=' + (limit);
  //   }
  //   if (select) {
  //     url += '&select=' + (select);
  //   }
  //   if (where) {
  //     url += '&where=' + JSON.stringify(where);
  //   }
  //   // url += '&populate=' + 'avatar';
  //   console.log({ where })
  //   console.log({ url })
  //   return request.callApi(url, 1, null, 'GET', null).then(res => {
  //     console.log({ res })
  //     if (res.status === 200) {
  //       return res
  //     }
  //     api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
  //   })
  // },



  actGetStorePartner: (skip, limit, select, where) => {
    // if (where) where = ();
    let url = 'api/storePartner'

    if (skip) {
      url += '?skip=' + (skip);
    }
    else {
      url += '?'
    }
    if (limit) {
      url += '&limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    // url += '&populate=' + 'avatar';
    console.log({ where })
    console.log({ url })
    return request.callApi(url, 1, null, 'GET', null).then(res => {
      console.log({ res })
      if (res.status === 200) {
        return res
      }
      // console.log(res.statusText)
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
    // return request.callApi(url, 'public', data, 'GET', null).then(res => res.json())
  },

  actUpdateUSerRequest: (user) => {
    console.log({ user })
    let url = `api/user/${user.id}`
    return request.callApi(url, 5, user, 'PATCH').then(res => {
      if (res.status === 200) {

        return api.actUpdateUser(res.data)
      }
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actUpdateUSerInfo: (user) => {
    console.log({ user })
    let url = `api/user/${user.id}`
    return request.callApi(url, 1, user, 'PATCH').then(res => {
      if (res.status === 200) {
        api.actUpdateUser(res.data)
        return res
      }
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actUpdateStorePartnerRequest: (storepartner) => {
    console.log({ storepartner })
    let url = `api/storePartner/${storepartner.id}`
    return request.callApi(url, 5, storepartner, 'PATCH').then(res => {
      if (res.status === 200) {

        return api.actUpdateStorePartner(res.data)
      }
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actUpdateConfigRequest: (config) => {
    console.log({ config })
    let url = `api/config/${config.id}`
    return request.callApi(url, 6, config, 'PATCH').then(res => {
      if (res.status === 200) {

        return api.actUpdateConfig(res.data)
      }
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },
  actUpdateStoreAreaRequest: (storearea) => {
    console.log({ storearea })
    let url = `api/storeArea/${storearea.id}`
    return request.callApi(url, 5, storearea, 'PATCH').then(res => {
      if (res.status === 200) {
        return api.actUpdateStoreArea(res.data)
      }
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actUpdateStoreRequest: (store) => {
    console.log({ store })
    let url = `api/store/${store.id}`
    return request.callApi(url, 5, store, 'PATCH').then(res => {
      if (res.status === 200) {
        api.actUpdateStore(res.data)
        return res
      }
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actUpdateProductRequest: (product) => {
    // console.log({ product })
    let url = `api/product/${product.id}`
    return request.callApi(url, 5, product, 'PATCH').then(res => {
      if (res.status === 200) {
        return api.actUpdateProduct(res.data)
      }
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actUpdateVoucherRequest: (voucher) => {
    console.log({ voucher })
    let url = `api/voucher/${voucher.id}`
    return request.callApi(url, 5, voucher, 'PATCH').then(res => {
      if (res.status === 200) {
        return api.actUpdateVoucher(res.data)
      }
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actUpdatePriceProductRequest: (priceproduct) => {
    console.log({ priceproduct })
    let url = `api/storeAreaProduct/${priceproduct.id}`
    return request.callApi(url, 5, priceproduct, 'PATCH').then(res => {
      if (res.status === 200) {
        return api.actUpdatePriceProduct(res.data)
      }
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actUpdateCategoryRequest: (category) => {
    console.log({ category })
    let url = `api/category/${category.id}`
    return request.callApi(url, 5, category, 'PATCH').then(res => {
      if (res.status === 200) {
        return api.actUpdateCategory(res.data)
      }
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actChangePassword: (password) => {
    let url = 'api/auth/change-password'
    return request.callApi(url, 1, password, 'PUT', null).then(res => {
      if (res.status === 200) {
        api.showMessage('Thay đổi mật khẩu thành công', 'Thông báo')
      }
      else {
        api.showMessage(res.data.message, 'Thông báo')
      }
    })
  },

  actChangePasswordStore: (password) => {
    let url = 'api/auth/change-password-store'
    return request.callApi(url, 4, password, 'PUT', null).then(res => {
      console.log('a', res)
      if (res.status === 200) {
        api.showMessage('Thay đổi mật khẩu thành công', 'Thông báo')
      }
      else {
        api.showMessage(res.data.message, 'Thông báo')
      }
    })
  },

  actResetPassword: (password) => {
    let url = 'api/auth/reset-password'
    return request.callApi(url, 4, password, 'PUT', null).then(res => {

      if (res.status === 200) {
        api.showMessage('Khôi phục mật khẩu thành công !', 'Thông báo')
        // return res

      }
      else {
        api.showMessage(res.data.message, 'Thông báo')
      }
    })
  },
  actBlockUser: (blockuser) => {
    let url = 'api/auth/user-management/block-user'
    return request.callApi(url, 1, blockuser, 'PUT', null).then(res => {
      if (res.status === 200) {
        // api.showMessage('Khôi phục mật khẩu thành công !', 'Thông báo')
        return res

      }
      else {
        api.showMessage('Hệ thông đang gặp lỗi ! Vui lòng thử lại trong vài phút !', 'Thông báo')
      }
    })
  },
  actUnBlockUser: (unblockuser) => {
    let url = 'api/auth/user-management/unblock-user'
    return request.callApi(url, 1, unblockuser, 'PUT', null).then(res => {
      if (res.status === 200) {
        // api.showMessage('Khôi phục mật khẩu thành công !', 'Thông báo')
        return res

      }

      api.showMessage('Hệ thông đang gặp lỗi ! Vui lòng thử lại trong vài phút !', 'Thông báo')
    })
  },
  actDeleteProductRequest: (id) => {
    let url = `api/product/${id}`
    return request.callApi(url, 5, null, 'DELETE').then(res => {
      if (res.status === 200) {
        return api.actDeleteProduct(id);
      }
      // console.log(res.statusText)
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actDeleteBillRequest: (id) => {
    let url = `api/bill/${id}`
    return request.callApi(url, 5, null, 'DELETE').then(res => {
      if (res.status === 200) {
        return api.actDeleteBill(id);
      }
      // console.log(res.statusText)
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actDeleteUserRequest: (id) => {
    let url = `api/user/${id}`
    return request.callApi(url, 5, null, 'DELETE').then(res => {
      if (res.status === 200) {
        return api.actDeleteUser(id);
      }
      // console.log(res.statusText)
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actDeleteCategoryRequest: (id) => {
    let url = `api/category/${id}`
    return request.callApi(url, 5, null, 'DELETE').then(res => {
      if (res.status === 200) {
        return api.actDeleteCategory(id);
      }
      // console.log(res.statusText)
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actDeletePriceProductRequest: (id) => {
    let url = `api/storeAreaProduct/${id}`
    return request.callApi(url, 5, null, 'DELETE').then(res => {
      if (res.status === 200) {
        return api.actDeletePriceProduct(id);
      }
      // console.log(res.statusText)
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actDeleteStoreUserRequest: (id) => {
    let url = `api/storeUser/${id}`
    return request.callApi(url, 4, null, 'DELETE').then(res => {
      if (res.status === 200) {
        return api.actDeleteStoreUser(id);
      }
      // console.log(res.statusText)
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },
  actDeleteRatingRequest: (id) => {
    let url = `api/rating/${id}`
    return request.callApi(url, 5, null, 'DELETE').then(res => {
      if (res.status === 200) {
        return api.actDeleteRating(id);
      }
      // console.log(res.statusText)
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },
  actDeleteStorePartnerRequest: (id) => {
    let url = `api/storePartner/${id}`
    return request.callApi(url, 5, null, 'DELETE').then(res => {
      if (res.status === 200) {
        return api.actDeleteStorePartner(id);
      }
      // console.log(res.statusText)
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },
  actRating: (rating) => {
    let url = 'api/bill-management/rating'
    return request.callApi(url, 'public', rating, 'POST', null).then(res => {
      if (res.status === 200) {
        return res
      }
    })
  },
  actBillCheck: (billId) => {
    let url = `api/bill-management/check-current-stage?billId=${billId}`
    return request.callApi2(url, 'public', billId, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
    })
  },

  actPushNotice: (notice) => {
    let url = 'api/socket/push-notice'
    return request.callApi(url, 5, notice, 'POST', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
      }
    })
  },
  actPushNoticeForAppUser: (notice) => {
    let url = 'api/app-user/push-notice'
    return request.callApi(url, 5, notice, 'POST', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
      }
    })
  },
  actRegistration: (registration) => {
    let url = 'api/bill-management/registration-receive-at-home'
    return request.callApi(url, 'public', registration, 'POST', null).then(res => {
      if (res.status === 200) {
        return res
      }
    })
  },

  actGetStoreSpecificProducts: (skip, limit, select, where, sort) => {
    let url = 'api/StoreSpecificProduct'

    if (skip) {
      url += '?skip=' + (skip);
    }
    else {
      url += '?'
    }
    if (limit) {
      url += '&limit=' + (limit);
    }
    url += '&createdAt=' + 'DESC';
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }
    url += '&populate=' + 'storeId,productId,activeBy';
    return request.callApi(url, 5, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actGetVKShareMonthlyStatistic: (skip, limit, select, where, sort) => {
    let url = 'api/VKShareMonthlyStatistic'


    if (skip) {
      url += '?skip=' + (skip);
    } else {
      url += '?'
    }

    if (limit) {
      url += '&limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }
    url += '&populate=userId';
    return request.callApi(url, 5, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        return api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actUpdateStoreSpecificProduct: (specific) => {
    let url = 'api/product-management/specific-store-price-action'
    return request.callApi(url, 5, specific, 'POST', null).then(res => {
      if (res.status === 200) {
        return res
      }
      // console.log(res.statusText)
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actGetReport: () => {
    let url = 'api/voucher-user-management/report-list-need-payment'
    return request.callApi(url, 5, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Lỗi hệ thống! Xin thử lại sau !')
      }
    })
  },
  actAddVoucherPartner: (data) => {
    let url = `api/voucher-partner/voucher-create?`;
    return request.callApi(url, 5, null, 'POST', null).then(res => {
      if (res.status === 200) {
        return res.data
      }
      else {
        api.showMessage('Lỗi hệ thống! Xin thử lại sau !')
      }
    })
  },
  actAddVoucherExportExcel: (where) => {
    console.log("111111111", JSON.stringify(where))
    let url = `api/voucher-partner/voucher-create?${queryString.stringify(where)}`;
    return request.report(url, 5).then(res => {
      if (where) {
        url += '&where=' + where;
      }
      if (res.status === 200) {
        return res.data
      }
      else {
        api.showMessage('Lỗi hệ thống! Xin thử lại sau ! (Chú ý thời gian kết thúc phải sau thời điểm hiện tại)')
      }
    })
  },
  actFetchListPartner: (skip, limit, select, where, sort) => {
    let url = 'api/partner'

    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }
    return request.callApi(url, 3, null, 'GET', null).then(res => {
      if (res.status === 200) {
        api.actFecthPartner(res.data.data)
        return res.data
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },
  actFetchPartnerReport: (skip, limit, select, from, to, where, sort) => {
    let url = 'api/partner/partner-report'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (from) {
      url += '&from=' + moment(from).startOf('day').valueOf()
    } else {
      url += '&from=' + (moment().startOf('month'));
    }
    if (to) {
      url += '&to=' + moment(to).endOf('day').valueOf();
    } else {
      url += '&to=' + (moment().endOf('month'));
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }
    return request.callApi(url, 5, null, 'GET', null).then(res => {
      if (res.status === 200) {
        api.actFecthPartnerReport(res.data.data.rows)
        return res.data
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },
  actFetchPartnerReportExportExcel: (from, to, where, sort) => {
    let url = 'api/partner/partner-report?exportExcel=1'

    // if (skip) {
    //   url += '?skip=' + (skip) + '&';
    // }
    // else {
    //   url += '?'
    // }
    // if (limit) {
    //   url += 'limit=' + (limit);
    // }
    // if (select) {
    //   url += '&select=' + (select);
    // }
    if (from) {
      url += '&from=' + moment(from).startOf('day').valueOf()
    } else {
      url += '&from=' + (moment().startOf('month'));
    }
    if (to) {
      url += '&toDate=' + moment(to).endOf('day').valueOf();
    } else {
      url += '&toDate=' + (moment().endOf('month'));
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    // if (sort) {
    //   url += '&sort=' + JSON.stringify(sort);
    // }
    return request.report(url, 5);
  },

  actFetchRevenueStructureExportExcel: (from, to, storeId, sort) => {
    let url = 'api/report/bill/detail-report?exportExcel=true&skip=0&limit=10000000000'
    if (from) {
      url += '&startDate=' + moment(from).startOf('day').valueOf()
    } else {
      url += '&startDate=' + (moment().startOf('month'));
    }
    if (to) {
      url += '&endDate=' + moment(to).endOf('day').valueOf();
    } else {
      url += '&endDate=' + (moment().endOf('month'));
    }
    if (storeId) {
      url += '&storeId=' + storeId;
    }
    return request.report2(url, 5);
  },

  actFetchCustomerReportExportExcel: (startDate, endDate, partnerId, sort) => {
    let url = 'api/report/voucher-partner/customer-active-voucher-partner?exportExcel=true&skip=0&limit=10000000000'

    // if (skip) {
    //   url += '?skip=' + (skip) + '&';
    // }
    // else {
    //   url += '?'
    // }
    // if (limit) {
    //   url += 'limit=' + (limit);
    // }
    // if (select) {
    //   url += '&select=' + (select);
    // }
    if (startDate) {
      url += '&startDate=' + moment(startDate).startOf('day').valueOf()
    } else {
      url += '&startDate=' + (moment().startOf('month'));
    }
    if (endDate) {
      url += '&endDate=' + moment(endDate).endOf('day').valueOf();
    } else {
      url += '&endDate=' + (moment().endOf('month'));
    }
    if (partnerId) {
      url += '&partnerId=' + partnerId;
    }
    // if (sort) {
    //   url += '&sort=' + JSON.stringify(sort);
    // }
    return request.report2(url, 5);
  },
  actAddPartner: (data) => {
    let url = "api/partner/create-partner";
    return request.callApi(url, 3, data, 'POST', null).then(res => {
      if (res.status === 200) {
        api.showMessage('Tạo thành công')
      } else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },
  actUpdatePartnerRequest: (data) => {
    let url = `api/partner/${data.id}`
    return request.callApi(url, 3, data, 'PATCH').then(res => {
      if (res.status === 200) {
        api.actUpdatePartner(res.data);
        api.showMessage("Cập nhật thành công")
      } else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },
  actGetRank: () => {
    let url = 'api/rank'
    return request.callApi(url, 5, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Lỗi hệ thống! Xin thử lại sau !')
      }
    })
  },
}

export default dataService;
